import React, { useState } from "react";
import toast from "react-hot-toast";
import { AiOutlineLoading } from "react-icons/ai";
import { useFormModal } from "../../contexts/formModal";
import { postVideos } from "../../services/video";
import { Button } from "../Orders/style";
import { Column, Form, Row } from "./style";

interface INewVideo {
  getVideo(): void;
  setVideos?(ev: any): void;
}

const NewVideoModal: React.FC<INewVideo> = ({ getVideo, setVideos }) => {

  const { closeModal } = useFormModal();
  const [loading, setLoading] = useState(false);
  const [payloadVideo, setPayloadVideo] = useState({
    codigo: "",
    observacao: "",
    quantidade: 1,
    nome: "",
  });

  const handlePayloadVideo = (key: any, value: any) => {
    if(key === 'quantidade'){

      if(value !== 0 && value < 1){
        toast.error('O valor não pode ser inferior a 1')
      } else {
        setPayloadVideo({ ...payloadVideo, [key]: value });
      }
    } else{
      setPayloadVideo({ ...payloadVideo, [key]: value });
    }
  };

  const postNewVideo = async (ev: any) => {
    ev.preventDefault();
    if(handleValidate()){
      setLoading(true)
      try {
        const result = await postVideos(payloadVideo)
        if(result.status === 201 && setVideos){
          let data = result.data
          setVideos((oldData: any) => { return { ...oldData, ...data}})
          toast.success('Gravado com sucesso.')
          closeModal()
          setLoading(false)
        }
      } catch (error) {
        console.error("Erro: ", error);
        closeModal()
      }
      setLoading(false)
    }
  };

  const handleValidate = () => {
    if(payloadVideo.codigo === ''){
      toast.error('Adicione um título')
    } else if(payloadVideo.quantidade < 1){
      toast.error('Digite a ordem de exibição do vídeo')
    } else if(payloadVideo.nome === ''){
      toast.error('Adicione a url do vídeo')
    } else if(payloadVideo.observacao === ''){
      toast.error('Adicione uma descrição')
    } else {
      return true;
    }
  }

  return (
    <Form onSubmit={(ev) => postNewVideo(ev)} autoComplete="off">
      <Row>
        <Column wide={"80%"} padd={"10px 20px"}>
          <label htmlFor="title">Título <span>*</span></label>
          <input 
            type="text" 
            name="title" 
            id="title" 
            onChange={({ target }) => handlePayloadVideo('codigo', target.value)}
            />
        </Column>
        <Column wide={"20%"} padd={"10px 20px"}>
          <label htmlFor="order">Ordem <span>*</span></label>
          <input 
            type="number" 
            name="order" 
            id="order" 
            min={1}
            // value={payloadVideo.quantidade}
            onChange={({ target }) => handlePayloadVideo('quantidade', +target.value)}
            />
        </Column>
      </Row>
      <Row>
        <Column padd={"10px 20px"}>
          <label htmlFor="link">Link <span>*</span></label>
          <input 
            type="url" 
            name="link" 
            id="link" 
            onChange={({ target }) => handlePayloadVideo('nome', target.value)}
            />
        </Column>
      </Row>
      <Row>
        <Column padd={"10px 20px"}>
          <label htmlFor="description">Descrição <span>*</span></label>
          <textarea
            name="description"
            id="description"
            cols={30}
            rows={10}
            onChange={({ target }) => handlePayloadVideo('observacao', target.value)}
          ></textarea>
        </Column>
      </Row>
      <Row jContent={"flex-end"} aItems={'center'}>
        {loading && (
          <span>
            <AiOutlineLoading />
          </span>
        )} &nbsp;
        <Button
          type="button"
          wide={"auto"}
          background={"var(--warning)"}
          onClick={() => closeModal()}
        >
          Cancelar
        </Button>
        <Button 
          type="submit"
          wide={"auto"}
          disabled={loading}
          >
            Salvar
        </Button>
      </Row>
    </Form>
  );
};

export default NewVideoModal;
