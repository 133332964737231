import React, { useEffect, useState } from 'react';
import { useAuth } from '../../contexts/auth';
import { getDashboard } from '../../services/dashboard';
import { getOpenOrders } from '../../services/orders';
import { Container, Title, Content, Card, Row, ItemSkill } from './styles';

interface IValues {
  lucroAno: string;
  lucroDia: string;
  lucroMes: string;
  opAbertas: number;
  opFechadas: number;
  percentualAno: string;
  percentualDia: string;
}

const Home: React.FC = () => {
  const { user } = useAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const [valuesDashboard, setValuesDashboard] = useState<IValues | null>(null);
  const [openOrders, setOpenOrders] = useState();

  useEffect(() => {
    setTimeout(() => {
      getValues();
    }, 1000)
  }, [])

  const getValues = async () => {
    try {
      const result = await getDashboard(user?.id)
      if(result){
        setValuesDashboard(result)
      }
    } catch (error) {
      console.log('Erro: ', error)
    }
  }

  return (
    <Container>
      <Title>Página Inicial</Title>
      <Content>
        <Row>
          <Card>
            <h3>Lucro Mês</h3>
            <div className="item">
              <div className="skills"></div>
            </div>
            <div className="content">
              <p className='green'>{valuesDashboard?.lucroMes && `$ ${valuesDashboard.lucroMes}`}</p>
            </div>
          </Card>
          <Card>
            <h3>Operações Abertas</h3>
            <ItemSkill className="item" wide={`"${valuesDashboard?.opAbertas}%"`}>
              <div className="skills"></div>
            </ItemSkill>
            <div className="content">
              <p>{valuesDashboard?.opAbertas}</p>
            </div>
          </Card>
          <Card>
            <h3>Operações Fechadas</h3>
            <div className="item">
              <div className="skills"></div>
            </div>
            <div className="content">
              <p>{valuesDashboard?.opFechadas}</p>
            </div>
          </Card>
          <Card>
            <h3>Lucro Ano</h3>
            <div className="item">
              <div className="skills"></div>
            </div>
            <div className="content">
              <p className='green'>{valuesDashboard?.lucroAno && `$ ${valuesDashboard.lucroAno}`}</p>
            </div>
          </Card>
          <Card>
            <h3>% Lucro Ano</h3>
            <div className="item">
              <div className="skills"></div>
            </div>
            <div className="content">
              <p>{valuesDashboard?.percentualAno && `${valuesDashboard.percentualAno}%`}</p>
            </div>
          </Card>
          <Card>
            <h3>Lucro Dia</h3>
            <div className="item">
              <div className="skills"></div>
            </div>
            <div className="content">
              <p className='green'>{valuesDashboard?.lucroDia && `$ ${valuesDashboard.lucroDia}`}</p>
            </div>
          </Card>
          <Card>
            <h3>% Lucro Dia</h3>
            <div className="item">
              <div className="skills"></div>
            </div>
            <div className="content">
              <p>{valuesDashboard?.percentualDia && `${valuesDashboard.percentualDia}%`}</p>
            </div>
          </Card>
        </Row>
        
      </Content>
    </Container >
  );
}

export default Home;