import React, { useEffect, useState } from "react";
import { useFormik, FormikHelpers } from "formik";

import {
  Container,
  PaddingContainer,
  Content,
  Title,
  ProfilePhotoContainer,
  ProfilePhotoButton,
  ProfilePhoto,
  ProfilePhotoButtonOverlay,
  ProfileInfoContainer,
  ProfileSaveContainer,
  Form,
  Row,
  ProfileTokens,
  Column,
  Input,
  RowInput,
  Button,
  ButtonOutline,
  Select,
  ButtonSvg,
} from "./styles";
import { IoMdExit } from "react-icons/io";

import { useAuth } from "../../contexts/auth";
import FormInputs from "../FormInputs";
import { AiOutlineEye, AiOutlineEyeInvisible, AiOutlineLoading } from "react-icons/ai";
import ImageUser from "../../assets/images/user.png";
import { getBrokerAll, postTokens } from "../../services/broker";
import toast from "react-hot-toast";

interface IEntidadePayload {
  nome: string;
  email: string;
  telefone: string;
  celular: string;
  cep: string;
  endereco: string;
  numeroEndereco: string;
  bairro: string;
  cidade: string;
  uf: string;
  complemento: string;
}

interface ITokens {
  broker: string;
  api_key?: string;
  secret_key?: string;
}

interface ITelegram {
  code?: string;
}

interface IEntidadeValidation {
  nome?: string;
  email?: string;
  telefone?: string;
  celular?: string;
  cep?: string;
  endereco?: string;
  numeroEndereco?: string;
  bairro?: string;
  cidade?: string;
  uf?: string;
  complemento?: string;
}

const Profile: React.FC = () => {
  const { user, updateUser, signOut } = useAuth();
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [api_key, setApi_key] = useState(false);
  const [secret_key, setSecret_key] = useState(false);
  const [telegram, setTelegram] = useState(false);
  const [coin, setCoin] = useState(false);
  const [broker, setBroker] = useState([]);
  const [initialTokens, setInitialTokens] = useState<ITokens>({
    broker: "",
    api_key: "",
    secret_key: "",
  });
  const [codeTelegram, setCodeTelegram] = useState<ITelegram>({
    code: "",
  });

  useEffect(() => {
    getBroker();
  }, []);

  const fields = {
    nome: {
      id: "nome",
      placeholder: "Digite seu nome...",
      label: "Nome",
    },
    email: {
      id: "email",
      placeholder: "Digite seu email...",
      label: "E-mail",
    },
    row0: [
      {
        id: "telefone",
        placeholder: "Digite seu telefone...",
        label: "Telefone",
        mask: "(99) 99999-9999",
      },
      {
        id: "celular",
        placeholder: "Digite seu celular...",
        label: "Celular",
        mask: "(99) 99999-9999",
      },
    ],
    cep: {
      id: "cep",
      placeholder: "Digite seu CEP...",
      label: "CEP",
      mask: "99999-999",
    },
    row1: [
      {
        id: "endereco",
        placeholder: "Digite seu logradouro...",
        label: "Logradouro",
      },
      {
        id: "numeroEndereco",
        placeholder: "N°",
        label: "Número",
      },
    ],
    row2: [
      {
        id: "bairro",
        placeholder: "Digite seu bairro...",
        label: "Bairro",
      },
      {
        id: "cidade",
        placeholder: "Digite sua cidade...",
        label: "Cidade",
      },
      {
        id: "uf",
        placeholder: "Digite seu estado...",
        label: "Estado",
      },
    ],
    complement: {
      id: "complemento",
      placeholder: "Digite o complemento do endereço...",
      label: "Complemento",
    },
  };

  const initialValues: IEntidadePayload = {
    nome: user?.nome || "",
    email: user?.email || "",
    telefone: user?.telefone || "",
    celular: user?.celular || "",
    cep: user?.cep || "",
    endereco: user?.endereco || "",
    numeroEndereco: user?.numeroEndereco || "",
    bairro: user?.bairro || "",
    cidade: user?.cidade || "",
    uf: user?.uf || "",
    complemento: user?.complemento || "",
  };

  const getBroker = async () => {
    try {
      const result = await getBrokerAll();

      if (result) {
        setBroker(result);
      }
    } catch (error) {
      console.error("Erro: ", error);
    }
  };

  const handleSubmit = (
    values: IEntidadePayload,
    helpers: FormikHelpers<IEntidadePayload>
  ) => {
    helpers.setSubmitting(false);
  };


  const validate = (values: IEntidadePayload) => {
    const errors: IEntidadeValidation = {};

    if (!values.nome) {
      errors.nome = "* Obrigatório";
    }

    if (!values.telefone) {
      errors.telefone = "* Obrigatório";
    }

    if (!values.email) {
      errors.email = "* Obrigatório";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Este endereço de e-mail é inválido";
    }

    return errors;
  };

  const handleValueToken = (key: string, value: string) => {
    setInitialTokens({
      ...initialTokens,
      [key]: value,
    });
  };

  const handleValueTelegram = (value: string) => {
    setCodeTelegram({ ...codeTelegram, code: value });
  };

  const userForm = useFormik({
    initialValues,
    validate,
    validateOnChange: false,
    onSubmit: handleSubmit,
  });

  const renderUserInfo = () => {
    return (
      <>
        <fieldset>
          <span>Nome</span>
          <p>{user?.nome}</p>
        </fieldset>
        <fieldset>
          <span>E-mail</span>
          <p>{user?.email}</p>
        </fieldset>
        {/* <fieldset>
          <span>CPF</span>
          <p>{user?.cpf}</p>
        </fieldset> */}
        <fieldset>
          <span>Telefone</span>
          <p>{user?.telefone}</p>
        </fieldset>
        {/* <fieldset>
          <span>Estabelecimento</span>
          <p>Nome do Estabelecimento</p>
        </fieldset>
        <fieldset>
          <span>Grupo</span>
          <p>Nome do Grupo</p>
        </fieldset> */}
        <fieldset>
          <span>Endereço</span>
          <p>
            {user?.endereco} {user?.numeroEndereco && `,${user.numeroEndereco}`} {user?.bairro && `.${user.bairro}, `}{" "}
            {user?.cidade} {user?.uf && ` - ${user.uf}`} {user?.cep && `(${user?.cep})`} {user?.complemento && `. ${user.complemento}.`}
          </p>
        </fieldset>
      </>
    );
  };

  const renderUserForm = () => {
    return (
      <Form onSubmit={userForm.handleSubmit}>
        <FormInputs fields={fields} form={userForm} />
      </Form>
    );
  };

  const handleSubmitToken = async () => {
    if (validateToken()) {
      setLoading(true);
      try {
        const result = await postTokens(user?.id, initialTokens);

        if (result.status === 201) {
          updateUser(result.data);
          toast.success("Salvo com sucesso.");
          setLoading(false);
        }
      } catch (error) {
        console.error("Erro: ", error);
      }
      setLoading(false);
    }
  };

  const validateToken = () => {
    if (initialTokens.broker === "") {
      toast.error("Selecione a corretora.");
    } else if (
      initialTokens.api_key === "" ||
      initialTokens.secret_key === ""
    ) {
      toast.error("Preencha os campos API Key e Secret Key");
    } else {
      return true;
    }
  };

  return (
    <Container>
      <PaddingContainer>
        <Row>
          <Title>Bem-vindo(a), {user?.nome}</Title>
          <button className="signOut" onClick={signOut}>
            <IoMdExit size={32} color="var(--primary-text-color)" />
          </button>
        </Row>
        <Content>
          <Column>
            <ProfilePhotoContainer>
              <ProfilePhotoButton>
                <ProfilePhoto
                  src={user?.foto || ImageUser}
                  alt="foto do usuário"
                />
                <ProfilePhotoButtonOverlay>
                  <p>Escolher a foto de perfil</p>
                </ProfilePhotoButtonOverlay>
              </ProfilePhotoButton>
            </ProfilePhotoContainer>
            <ProfileTokens>
              <header>
                <p>Status</p>
              </header>
              <hr />

              <RowInput>
                <Column>
                  <Row bottom={"10px"}>
                    <label htmlFor="account">
                      Status da Conta: <span className={user?.inativo == false ? "green" : "red"}>{user?.inativo == false ? "Ativa": "Inativa"}</span>
                    </label>
                  </Row>
                  <Row bottom={"10px"}>
                    <label htmlFor="api">
                      Status da API: <span className="green">Ativa</span>
                    </label>
                  </Row>
                  <Row bottom={"10px"}>
                    <label htmlFor="notification">
                      Status da Notificação:{" "}
                      <span className="green">Ativa</span>
                    </label>
                  </Row>
                </Column>
              </RowInput>
            </ProfileTokens>
          </Column>
          <Column>
            {/* Inicio Section Perfil */}
            <ProfileInfoContainer>
              <header>
                <p>Informações de contato</p>
                <button onClick={() => setIsEditing(!isEditing)}>editar</button>
              </header>
              <hr />
              {isEditing ? renderUserForm() : renderUserInfo()}
            </ProfileInfoContainer>
            {/* Fim Section Perfil */}

            {/* Inicio Section Token */}
            <ProfileTokens>
              <header>
                <p>Token de acesso</p>
              </header>
              <hr />
              <RowInput>
                <Column>
                  <Row bottom={"6px"}>
                    <Column>
                      <label htmlFor="select_api">Selecione a Corretora</label>
                      <Select
                        wide={"40%"}
                        name="select_api"
                        id="select_api"
                        onChange={({ target }) =>
                          handleValueToken("broker", target.value)
                        }
                      >
                        <option hidden>Selecione...</option>
                        {broker &&
                          broker.map((item: any, index: any) => (
                            <option value={item.id} key={index}>
                              {item.nome}
                            </option>
                          ))}
                      </Select>
                    </Column>
                    {loading && (
                      <span className="loading">
                        <AiOutlineLoading /> &nbsp;
                      </span>
                    )}
                  </Row>
                  <Row bottom={"0"}>
                    <Column>
                      <label htmlFor="api_key">API Key</label>
                      <div className="token">
                        <div className="d-flex">
                          <Input
                            id="api_key"
                            name="api_key"
                            transparent={user?.satp ? true : false}
                            placeholder={user?.satp ? "...................................." : "Digite..."}
                            // label="Binance"
                            autoComplete="off"
                            type={api_key ? "text" : "password"}
                            onChange={({ target }) =>
                              handleValueToken("api_key", target.value)
                            }
                          />
                          <ButtonSvg
                            type="button"
                            onClick={() => setApi_key(!api_key)}
                            transparent={user?.satp ? true : false}
                          >
                            {api_key ? (
                              <AiOutlineEye />
                            ) : (
                              <AiOutlineEyeInvisible />
                            )}
                          </ButtonSvg>
                        </div>

                        <Button disabled={loading} onClick={() => handleSubmitToken()}>Atualizar</Button>
                      </div>
                    </Column>
                  </Row>
                  <Row>
                    <Column>
                      <label htmlFor="secret_key">Secret Key</label>
                      <div className="token">
                        <div className="d-flex">
                          <Input
                            id="secret_key"
                            name="secret_key"
                            transparent={user?.satp ? true : false}
                            placeholder={user?.satp ? "...................................." : "Digite..."}
                            autoComplete="off"
                            type={secret_key ? "text" : "password"}
                            onChange={({ target }) =>
                              handleValueToken("secret_key", target.value)
                            }
                          />
                          {/* <button
                            type="button"
                            onClick={() => setSecret_key(!secret_key)}
                          >
                            {secret_key ? (
                              <AiOutlineEye />
                            ) : (
                              <AiOutlineEyeInvisible />
                            )}
                          </button> */}
                        </div>

                        <ButtonOutline>Excluir</ButtonOutline>
                      </div>
                    </Column>
                  </Row>
                </Column>
              </RowInput>
              {/* <RowInput>
                <Input 
                  id="PooCoin"
                  name="PooCoin"
                  placeholder="Digite..."
                  label="Poo Coin"
                  type={coin ? "text" : "password"}
                  onChange={(ev) => handleValueToken('coin', ev.target.value)}
                />
                <button type='button' onClick={() => setCoin(!coin)}>
                  {coin ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                </button>
              </RowInput> */}
            </ProfileTokens>
            {/* Fim Section Token */}

            {/* Inicio Section Telegram */}
            <ProfileTokens>
              <header>
                <p>Notificação Telegram</p>
              </header>
              <hr />
              <RowInput>
                <Column>
                  <label htmlFor="cod_telegram">Código Telegram</label>
                  <div className="token">
                    <div className="d-flex">
                      <Input
                        wide={"70%"}
                        id="cod_telegram"
                        name="cod_telegram"
                        autoComplete="off"
                        type={telegram ? "text" : "password"}
                        onChange={(ev) => handleValueTelegram(ev.target.value)}
                      />
                      <ButtonSvg
                        type="button"
                        onClick={() => setTelegram(!telegram)}
                        // transparent={user?.satp ? true : false}
                      >
                      {telegram ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                    </ButtonSvg>
                    </div>
                    <Button margin={"auto"}>Atualizar</Button>
                  </div>
                </Column>
              </RowInput>
              <RowInput>
                <div className="finished">
                  <input type="checkbox" name="finiched" id="finished" />
                  <label htmlFor="finished">
                    Posições finalizadas com sucesso
                  </label>
                </div>
                <ButtonOutline wide={"auto"} margin={"auto"}>
                  Desconectar Telegram
                </ButtonOutline>
              </RowInput>
            </ProfileTokens>
            {/* Fim Section Telegram */}
          </Column>
        </Content>
      </PaddingContainer>
      {isEditing && (
        <ProfileSaveContainer showing={isEditing}>
          <Button>Salvar</Button>
          <Button onClick={() => setIsEditing(false)}>Cancelar</Button>
        </ProfileSaveContainer>
      )}
    </Container>
  );
};

export default Profile;
