import Provider from './config'

export const getBalance = async (id: any) => {
  try {
    const result = await Provider.get('exchanges/account/binanceInfo', {
      params: { idUser: id }
    })

    return result.data;
  } catch (err: any) {
    throw Error ('Erro ao buscar o saldo.')
  }
}

export const getOpenOrders = async (currentPage: number, id: any) => {
  try {
    const result = await Provider.get('pedidos', {
      params: {
        idUser: id,
        classe: "V Crypto P Invest",
        page: currentPage, 
        baixa: false
      }
    })

    return result.data;
  } catch (err: any) {
    throw Error ('Erro ao buscar ordens abertas.')
  }
}

export const getClosedOrders = async (currentPage: number, id: any) => {
  try {
    const result = await Provider.get('pedidos', {
      params: {
        idUser: id,
        classe: "V Crypto P Invest",
        page: currentPage, 
        baixa: true
      }
    })

    return result.data
  } catch (err: any) {
    throw Error ('Erro ao buscar ordens fechadas.')
  }
}

export const getFilterOrders = async (currentPage: number, filter: any, id: any) => {
  try {
    const result = await Provider.get('pedidos', {
      params: {
        idUser: id,
        classe: "V Crypto P Invest",
        page: currentPage, 
        baixa: false, 
        filter: filter
      }
    })

    return result.data;
  } catch (err: any) {
    throw Error ('Erro ao buscar ordens abertas.')
  }
}

export const getFields = async (id: any) => {
  try {
    const result = await Provider.get('pedidos/campos', {
      params: {
        idUser: id,
        classe: "V Crypto P Invest"
      }
    })

    return result.data
  } catch (err: any) {
    throw Error ('Erro ao buscar os campos da tabela.')
  }
}

export const postOrders = async (payload: any) => {
  try {
    const response = await Provider.post(`exchanges/account/binance/open-order/`, payload)

    return response;
  } catch (err: any) {
    throw Error ('Erro ao enviar os dados.')
  }
} 