import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { AiOutlineLoading } from "react-icons/ai";
import { useAuth } from "../../contexts/auth";
import { useFormModal } from "../../contexts/formModal";
import { postOrders } from "../../services/orders";
import { getWhiteList, getWhiteListOnlyNames } from "../../services/whiteList";
import { Form, Column, RowModal, Row, Button } from "./style";

interface IReload {
  getTableFilds: () => void;
  getCurrentBalance: () => void;
}

const LabelModal: React.FC<IReload> = ({ getTableFilds, getCurrentBalance }) => {
  const { user } = useAuth();
  const { closeModal } = useFormModal();
  const [coin, setCoin] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [newOrder, setnewOrder] = useState({
    estrategia: "automatizada",
    valor: 0,
    lucroEsperado: 1,
    userId: user ? user.id : "",
    moeda: "",
  });

  useEffect(() => {
    getCoin();
  }, []);

  const getCoin = async () => {
    try {
      const result = await getWhiteListOnlyNames();

      if (result) {
        const filterCoin = result.map((item: any) => item.observacao);
        setCoin(filterCoin);
      }
    } catch (error) {
      console.error("Erro", error);
    }
  };

  const handleNewOrder = (key: string, value: any) => {
    if(key === 'valor' || key === 'lucroEsperado'){
      if(value <= 0){
        toast.error('O valor não pode ser inferior a 1')
      } else {
        setnewOrder((oldValue) => {
          return { ...oldValue, [key]: value };
        });  
      }
    } else {
      setnewOrder((oldValue) => {
        return { ...oldValue, [key]: value };
      });
    }
  };

  const postOrder = async (ev: any) => {
    ev.preventDefault();
    if (handleValidate()) {
      if(user?.satp){
        setLoading(true);
        const _newOrder = newOrder;
        const valor = _newOrder.valor.toString();
        const { estrategia, lucroEsperado, userId, moeda } = _newOrder;
  
        try {
          if(estrategia === 'personalizada'){
            const result = await postOrders({valor, estrategia, lucroEsperado, userId, moeda});
            if (result.status === 201) {
              toast.success("Salvo com sucesso");
              closeModal();
              await getCurrentBalance();
              await getTableFilds();
              setLoading(false);
            }
          } else {
            const result = await postOrders({
              valor,
              estrategia,
              lucroEsperado,
              userId,
            });
            if (result.status === 201) {
              toast.success("Salvo com sucesso");
              closeModal();
              await getCurrentBalance();
              await getTableFilds();
              setLoading(false);
            }
          }
        } catch (error) {
          console.error("Erro: ", error);
          closeModal();
          toast.error("Erro ao criar nova ordem.");
        }

      } else {
        toast.error('Cadastre o Token de acesso no perfil do usuário')
      }
      setLoading(false);
    }
  };

  const handleValidate = () => {
    if (newOrder.valor < 12) {
      toast.error("Digite um valor válido.");
    } else if(newOrder.estrategia !== "automatizada" && newOrder.moeda === "") {
      toast.error("Selecione a moeda ou altere a opção de entrada")
    }  else {
      return true;
    }
  };

  return (
    <Form onSubmit={(ev) => postOrder(ev)}>
      <Column>
        <RowModal>
          <Column>
            <label htmlFor="estrategia">Opções de Entrada</label>
            <select
              name="estrategia"
              id="estrategia"
              onChange={(ev) => handleNewOrder("estrategia", ev.target.value)}
            >
              <option value="automatizada">Automatizada</option>
              <option value="personalizada">Personalizada</option>
            </select>
          </Column>
          {newOrder.estrategia === "personalizada" && (
            <Column>
              <label htmlFor="moeda">Moeda</label>
              <select 
                name="moeda" 
                id="moeda"
                onChange={(ev) => handleNewOrder("moeda", ev.target.value)}
                >
                <option value="" hidden>Selecione...</option>
                {coin &&
                  coin.map((item: any, index: any) => (
                    <option value={item} key={index}>
                      {item}
                    </option>
                  ))}
              </select>
            </Column>
            // ) : (

            //   <Column>
            //     <label htmlFor="qtd">Quantidade</label>
            //     <input type="number" name="qtd" id="qtd" placeholder="1"/>
            //   </Column>
          )}
        </RowModal>
        <RowModal>
          <Column>
            <label htmlFor="valor">Valor Entrada</label>
            <input
              type="number"
              placeholder="Mínimo $12"
              name="valor"
              id="valor"
              value={newOrder.valor > 0 ? newOrder.valor : ''}
              onChange={(ev) => handleNewOrder("valor", +ev.target.value)}
            />
          </Column>
          <Column>
            <label htmlFor="lucroEsperado">Valor Venda</label>
            <div className="input-group">
              <input
                type="number"
                id="lucroEsperado"
                placeholder="1"
                value={newOrder.lucroEsperado}
                onChange={(ev) =>
                  handleNewOrder("lucroEsperado", +ev.target.value)
                }
              />
              <span className="input-group-addon">%</span>
            </div>
          </Column>
        </RowModal>
        <Row className="flex-end">
          <Button
            type="button"
            wide={"auto"}
            background={"var(--warning)"}
            onClick={() => closeModal()}
          >
            Cancelar
          </Button>

          <Button
            type="submit"
            wide={"auto"}
            background={"var(--primary)"}
            disabled={loading}
          >
            Adicionar Ordem &nbsp;
            {loading && (
              <span>
                <AiOutlineLoading />
              </span>
            )}
          </Button>
        </Row>
      </Column>
    </Form>
  );
};

export default LabelModal;
