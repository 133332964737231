import styled from "styled-components";
import ButtonLayout from "../../components/Button";

interface ILength {
  firstItem?: string
  secondItem?: string
  wide?: string;
  flex?: string;
  pad?: string;
}

export const Container = styled.div`
  padding: 16px 0;
  width: 100%;
`;

export const Title = styled.p`
  color: var(--black);
  font-size: 24px;
  margin-bottom: 16px;
`;

export const Content = styled.div<ILength>`
  background: var(--form-background);
  color: var(--black);
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 90%;
  padding: ${props => props.pad ? props.pad : '10px 16px 30px 16px'};
`;

export const DivRow = styled.div<ILength>`
  display: flex;
  align-items: center;
  justify-content: ${props => props.flex ? props.flex : 'flex-start'};
  padding: 10px 0 0 0;
  width: 100%;

  div{
    margin-right: 100px;
  }

  label{
    color: var(--black);
    font-size: 22px;
    margin-right: 60px;
  }
  
  .buttonOrder{
    background: transparent;
    padding: 0;
  }
  
  p{
    color: var(--black);
    cursor: pointer;
    font-size: 1rem;
  }
  
  p:first-child{
    margin-right: 20px;
  }
  
  .primary{
    color: var(--primary-lighter);
  }

  .button-list{
    background: var(--white);
    border: 2px solid var(--primary-lighter);
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 4px;
    padding: 6px;

    &:hover {
      background: var(--primary-lighter);
      border: 2px solid var(--primary-lighter);
      color: var(--white);
    }

    & > svg {
      font-size: 30px;
    }
  }
`;

export const DivColumn = styled.div<ILength>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  .rdl-filter-container{
    display: flex;
  }

  .rdl-filter-container > input{
    border: none;
    border-radius: 4px;
    height: 30px;
    margin-bottom: 8px;
    padding: 4px;
    width: 150px;
  }

  .rdl-list-box:first-child > .rdl-filter-container::after {
    content: ${props => props.firstItem};
    border: none;
    border-radius: 4px;
    background: var(--white);
    height: 23px;
    margin-bottom: 6px;
    margin-left: auto;
    padding: 4px;
    text-align: center;
    width: 50px;
  }
  
  .rdl-list-box:last-of-type > .rdl-filter-container::after{
    content: ${props => props.secondItem};
    border: none;
    border-radius: 4px;
    background: var(--white);
    height: 23px;
    margin-bottom: 6px;
    margin-left: auto;
    padding: 4px;
    text-align: center;
    width: 50px;
  }

  .react-dual-listbox {
    display: flex;
    width: 100%;
  }

  .rdl-control-label {
    display: none;
  }

  .rdl-list-box {
    width: 50%;
  }

  select {
    background: var(--white);
    outline: 3px solid var(--primary-lighter);
    outline-offset: -2px;
    box-sizing: border-box;
    border-radius: 4px;
    height: auto;
    min-height: 150px;
    padding: 2px 0;
    width: 100%;
  }

  select > option{
    border-bottom: 2px solid var(--primary-lighter);
    padding: 1px 6px;
    text-align-last: justify;
  }


  .rdl-actions {
    display: flex;
    flex-direction: column;
    margin: 50px 10px 30px 10px;
  }

  .rdl-actions-right > button,
  .rdl-actions-left > button {
    background: transparent;
  }

  .rdl-actions-right > button > .button-list,
  .rdl-actions-left > button > .button-list {
    background: var(--white);
    border: 2px solid var(--primary-lighter);
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 4px;
    padding: 6px;

    &:hover {
      background: var(--primary-lighter);
      border: 2px solid var(--primary-lighter);
      color: var(--white);
    }

    & > svg {
      font-size: 30px;
    }
  }
`;

export const DivCard = styled.div`
  background-color: var(--form-background);
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  min-height: 60px;
  padding: 10px 20px;
  width: 100%;

  & > div:nth-child(2){
    justify-content: flex-end;
  }

  @media(max-width: 1024px){
    .filters {
      width: 100%;
    }  
  }

`;

export const DivLabel = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  div:nth-child(1) {
    width: 52%;
  }

  div:nth-child(2) {
    width: 48%;
  }

  div {
    margin-bottom: 8px;
  }

  div > label {
    color: var(--white);
  }
`;

export const Button = styled(ButtonLayout)<ILength>`
  margin-top: 24px;
  margin-bottom: 16px;
  width: ${props => props.wide ? props.wide : '100%'};

  & > span > svg{
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(359deg); }  
  }
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 30px;
  width: 100%;

  label{
    color: var(--white);
    margin-right: 10px;
  }

  
  input {
    border: none;
    border-radius: 4px;
    height: 30px;
    margin-right: 4px;
    padding: 4px;
    width: 80px;
  }

  input:nth-child(1){
    margin-right: 10px;
    width: 100px;
  }

  button{
    background: transparent;
    cursor: pointer;
    margin-top: auto;

    & > .loading > svg{
      color: var(--primary);
      animation: spin 1s linear infinite;
    }

    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(359deg); }  
    }
  }

  svg{
    color: green;
    font-size: 30px;
  }

  @media(max-width: 668px){
    input, select{
      margin-top: 6px;
    }
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 36%;

  & > div {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 10px;
    width: 100%;
  }
`;

export const Select = styled.select`
  background: var(--white);
  border: none;
  border-radius: 4px;
  height: 30px;
  margin-right: 20px;
  padding: 2px 10px;
  width: 60px;
`;
