import styled from 'styled-components';
import {ILeftSidebarProps, IListLinkProps} from '.';

import ButtonLayout from '../Button';

export const Container = styled.div`
  background-color: var(--form-background);
  border: none;
  border-radius: 30px;
  min-width: 50px;
  height: auto;
  padding-top: 14px;
  margin-left: 8px;
  margin-top: auto;
  margin-bottom: auto;
  
  transition: .2s;
  -webkit-transition: .2s;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  
  
  &>ul>li:nth-child(n+2):nth-child(-n+7){
    margin-top: 30px;
    margin-bottom: 16px;
  }
  `;

export const ContainerModal = styled(Container)<ILeftSidebarProps>`
  box-shadow: ${props => props.isExpanded && "0 0 0.6rem var(--form-selected)"};
  margin-left: ${props => props.isExpanded ? '8px' : '8px'};
  min-width: ${props => props.isExpanded ? '12%' : '50px'};
  position: ${props => props.isExpanded ? "absolute" : "relative"};
  top: ${props => props.isExpanded && "24%"};
  z-index: ${props => props.isExpanded && 3};

  justify-content: ${props => props.isExpanded ? 'flex-start' : 'center'};

  /* animation: fadeInLeft .4s;
  -webkit-animation: fadeInLeft .4s;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  

  @keyframes fadeInLeft {
    0% {
      opacity: 0;
      transform: translateX(-100px);
      -webkit-transform: translateX(-100px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
      -webkit-transform: translateX(0);
    }
  } */
  
  
  @media (max-width: 1024px) {
    ${props => props.isExpanded ? '& {position: absolute; z-index: 3; background-color: var(--primary); color: var(--white);}' : ''}
  }
`;

export const List = styled.ul`
  list-style: none;
  `;

export const ListModal = styled.ul<ILeftSidebarProps>`
  width: ${props => props.isExpanded === false ? '' : '100%'};
`;

export const ListItem = styled.li``;

export const ListLink = styled.button<IListLinkProps>`
  background-color: ${props => props.selected ? 'var(--primary)' : 'transparent'};
  border-radius: 24px;
  color: ${props => props.selected ? 'var(--white)' : 'var(--primary-text-color)'};
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 300;
  height: 40px;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  width: 40px;
  text-decoration: none;
  white-space: nowrap;
  

  & svg {
    margin-right: 0px;
    width: 24px;
    height: 24px;
  }
  
  & > .arrow-down {
    margin-left: 8px;
  }
  
  &:hover {
    background-color: var(--primary);
    color: var(--white);
  }
  
  @media (max-width: 1024px) {
    ${props => props.expanded === "false"
    ? props.selected
    ? '&{background-color: var(--primary); color: var(--white);}'
    : '&{background-color: transparent; color: var(--primary);}' 
    : '&{background-color: transparent; color: var(--white);}'}
    ${props => 
      props.expanded === "false" 
      ?  props.selected 
      ? '&{color: var(--white);}' 
      : '&{color: var(--primary-text-color)}'
      : '&:hover{background-color: var(--white); color: var(--primary);}'}
  }
  `;

export const ListLinkModal = styled(ListLink)`
  height: ${props => props.expanded === "false" ? '40px' : '40px'};
  ${props => props.expanded === "false" ? 'width: 40px;' : 'width: auto;'}
  ${props => props.expanded === "false" ? 'justify-content: center;' : ''}
  margin-left: ${props => props.expanded === "false" ? '0' : props.level ? props.level * 20 : '18'}px;
  margin-right: ${props => props.expanded === "false" ? '0' : props.level ? props.level * 40 : '20'}px;
  padding: 0px 16px 0px 12px;

  & svg {
      margin-right: 8px;
      width: 18px;
      height: 18px;
  }
`

export const Button = styled(ButtonLayout)`
  color: var(--primary-text-color);
  font-size: 14px;
  font-weight: 500;
  margin-top: 24px;
  margin-bottom: 20px;

  @media (max-width: 1024px) {
    color: var(--white);
  }
`;