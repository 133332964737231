import Provider from './config'

export const getVideos = async () => {
  try {
    const result = await Provider.get('/tabela', {
      params: {
        classe: 'Videos de Treinamento'
      }
    })

    return result.data
  } catch (err: any) {
    throw Error ('Erro ao buscar vídeos')
  }
}

export const postVideos = async (payload: any) => {
  try {
    const response = await Provider.post('/tabela', payload, {
      params: {
        classe: 'Videos de Treinamento'
      }
    })

    return response
  } catch (err: any) {
    throw Error ('Erro ao enviar novo vídeo')
  }
}