import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { AiOutlineLoading } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import ListTable, { IField } from "../../components/ListTable";
import { IToggleMenuOption } from "../../components/ToggleMenuButton";
import { useAuth } from "../../contexts/auth";
import { useFormModal } from "../../contexts/formModal";
import {
  getBalance,
  getClosedOrders,
  getFields,
  getFilterOrders,
  getOpenOrders,
  postOrders,
} from "../../services/orders";
import { getWhiteList } from "../../services/whiteList";
import { orderCols } from "../../utils/form";
import LabelModal from "./labelModal";
import {
  Button,
  Column,
  Container,
  DivCard,
  Row,
  RowModal,
  Title,
  Form,
  Content,
  Footer,
  SelectedContainer,
  PaginateContainer,
} from "./style";

interface IOrder {
  title?: string;
  multipleSelect: boolean;
}

export interface IResponse {
  docs: any[];
  totalPages: number;
  total: number;
}

const Order: React.FC<IOrder> = ({ title, multipleSelect }) => {
  const { user } = useAuth();
  const { openModal } = useFormModal();
  const [balance, setBalance] = useState([]);
  const [orders, setOrders] = useState<IResponse | null>(null);
  const [filteredOrders, setFilteredOrders] = useState<IResponse | null>(null);
  const [ordersClosed, setOrdersClosed] = useState<IResponse | null>(null);
  const [filteredOrdersClosed, setFilteredOrdersClosed] = useState<IResponse | null>(null);
  const [loading, setLoading] = useState(false);
  const [openOrders, setOpenOrders] = useState(true)
  const [selectedIndexes, setSelectedIndexes] = useState<boolean[]>([]);
  const [cols, setCols] = useState<Array<IField>>([]);
  const [colsOrdersClosed, setColsOrdersClosed] = useState<Array<IField>>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageClosed, setCurrentPageClosed] = useState(1);
  const [actionsMenus, setActionsMenus] = useState<IToggleMenuOption[]>([]);
  const [coin, setCoin] = useState<any>([]);
  const [broker, setBroker] = useState<any>([]);
  const [filterOrders, setFilterOrders] = useState({
    corretora: 'todasCorretoras',
    moeda: 'todosPares'
  })

  useEffect(() => {
    getTableFilds();
  }, [currentPage]);
  
  useEffect(() => {
    getTableFildsClosed();
  }, [currentPageClosed]);

  useEffect(() => {
    if(filterOrders.corretora !== 'todasCorretoras' || filterOrders.moeda !== 'todosPares'){
      // filterOrder()
    }
  }, [filterOrders, openOrders])


  const handleFilter = (key: string, value: string) => {
    setFilterOrders({
      ...filterOrders,
      [key]: value
    })
  }


  const getCurrentBalance = async () => {
    setLoading(true);
    try {
      const result = await getBalance(user?.id);
      if (result) {
        const filterBalance = result.balances.filter((item: any) => {
          if (
            item.asset === "USDT" ||
            // item.asset === "BUSD" ||
            item.asset === "BRL"
          ) {
            return {
              asset: item.asset,
              free: item.free,
            };
          }
        });
        setBalance(filterBalance);
        setLoading(false);
      }
    } catch (error) {
      toast.error('Erro ao consultar saldo')
      console.error("Erro: ", error);
    }
    setLoading(false);
  };

  const getTableFilds = async () => {  // Função responsável por dar get em todas as colunas da tabelas e as ordens abertas
    try {
      const result = await getFields(user?.id);
      if (result) {

        const openOrders = result.filter((item: any) => item.campo !== "aprovacao" && item.campo !== "comissao" && item.campo !== "lucroLiquido")

        const openOrders_cols = openOrders.sort(orderCols);
        const closedOrders_cols = result.sort(orderCols);

        setCols(openOrders_cols);
        setColsOrdersClosed(closedOrders_cols)

        const resultOrders = await getOpenOrders(currentPage, user?.id);
        if (resultOrders) {
          const newCoin: any = []
          const newBroker: any = []

          const filterCoin = resultOrders.docs.map((item:any) => item.moeda)
          const _filterCoin = new Set(filterCoin)

          const filterBroker = resultOrders.docs.map((item:any) => item.Pessoa.nome)
          const _filterBroker = new Set(filterBroker)

          _filterCoin.forEach((item) => {newCoin.push(item)})
          _filterBroker.forEach((item) => {newBroker.push(item)})

          setCoin(newCoin)
          setBroker(newBroker)
          setOrders(resultOrders);
        }
      }
    } catch (error) {
      toast.error('Erro ao buscar ordens abertas')
      console.error("Erro: ", error);
    }
  };

  const getTableFildsClosed = async () => { // Função responsável por dar get nas ordens fechadas
    try {
        const resultOrders = await getClosedOrders(currentPageClosed, user?.id);

        if (resultOrders) {
          const newCoin: any = []
          const newBroker: any = []

          const filterCoin = resultOrders.docs.map((item:any) => item.moeda)
          const _filterCoin = new Set(filterCoin)

          const filterBroker = resultOrders.docs.map((item:any) => item.Pessoa.nome)
          const _filterBroker = new Set(filterBroker)

          _filterCoin.forEach((item) => {newCoin.push(item)})
          _filterBroker.forEach((item) => {newBroker.push(item)})

          setCoin(newCoin)
          setBroker(newBroker)
          setOrdersClosed(resultOrders);
        }
    } catch (error) {
      toast.error('Erro ao buscar ordens fechadas')
      console.error("Erro: ", error);
    }
  };

  const filterOrder = () => { // Função responsável por realizar um filtro de acordo com a opção que o usuário escolher
    const _openOrder = orders?.docs
    const _closedOrder = ordersClosed?.docs

    if(openOrders){
      if(filterOrders.corretora !== "todasCorretoras"){
        if(_openOrder){
          const itens = _openOrder?.filter((item: any) => item.Pessoa.nome === filterOrders.corretora)

          setFilteredOrders({
              ...orders, 
              docs: itens,
              total: itens?.length
            })
        }

      } else if(filterOrders.moeda !== "todosPares"){
        if(_openOrder){
          const itens = _openOrder?.filter((item: any) => item.moedas === filterOrders.moeda)

          setFilteredOrders({
              ...orders, 
              docs: itens,
              total: itens?.length
            })
        }
      } else {
        // setFilterOrders({...filteredOrders, docs: []})
      }
    } else {
      if(filterOrders.corretora !== "todasCorretoras"){

        if(_closedOrder){
          const itens = _closedOrder?.filter((item: any) => item.Pessoa.nome === filterOrders.corretora)

          setFilteredOrdersClosed({
              ...ordersClosed, 
              docs: itens,
              total: itens?.length
            })
        }
      } else if(filterOrders.moeda !== "todosPares"){
        if(_closedOrder){
          const itens = _closedOrder?.filter((item: any) => item.moedas === filterOrders.moeda)

          setFilteredOrdersClosed({
              ...ordersClosed, 
              docs: itens,
              total: itens?.length
            })
        }
      } else {
        // setFilteredOrdersClosed([])
      }
    }
  }

  const handleChangePage = (page: number) => {
    if(openOrders){
      if (page + 1 !== currentPage) {
        setCurrentPage(page + 1);
      }
    } else {
      if (page + 1 !== currentPageClosed) {
        setCurrentPageClosed(page + 1);
      }
    }
  };

  const handleModal = () => {
    openModal({
      type: "confirm",
      title: "Nova Ordem",
      children: () => 
        <LabelModal 
          getTableFilds={getTableFilds}
          getCurrentBalance={getCurrentBalance}
        />,
    });
  };

  const dataTable = () => {
    if(openOrders && filteredOrders){
      return filteredOrders.docs;
    } else if((openOrders && !filteredOrders) && orders){
      return orders.docs;
    } else if(!openOrders && filteredOrdersClosed){
      return filteredOrdersClosed.docs;
    } else if((!openOrders && !filteredOrdersClosed) && ordersClosed){
      return ordersClosed.docs;
    } else {
      return []
    }
  }

  

  return (
    <Container>
      <Title>{title}</Title>
      <DivCard>
        <Row wide={"80%"}>
          <label htmlFor="">Saldo</label>
          {balance.length > 0 &&
            balance.map((item: any, index: any) => (
              <div key={index}>
                <p>
                  {Number(item.free).toFixed(2)} {item.asset}
                </p>
              </div>
            ))}
        </Row>
        <Row wide={"20%"}>
          {loading && (
            <span>
              <AiOutlineLoading /> &nbsp;
            </span>
          )}
          <Button
            wide={"auto"}
            disabled={loading}
            onClick={() => getCurrentBalance()}
          >
            Atualizar
          </Button>
        </Row>
      </DivCard>
      <DivCard>
        <Row>
          <button className="buttonOrder" type="button" onClick={() => setOpenOrders(true)}>
            <p className={openOrders ? "primary" : ''}>Abertas{orders ? `(${orders.total})` : "(0)"}</p>
          </button>
          <button className="buttonOrder" type="button" onClick={() => setOpenOrders(false)}>
            <p className={!openOrders ? "primary" : ''}>Fechadas{ordersClosed ? `(${ordersClosed.total})` : "(0)"}</p>
          </button>
        </Row>
      </DivCard>

      <DivCard>
        <Row wide={"40%"} className="filters">
          <p className="primary">Filtros</p>
          <select 
            name="todasCorretoras" 
            id="todasCorretoras"
            onChange={(ev) => handleFilter('corretora', ev.target.value)}
            >
            <option value="todasCorretoras">Todas as Corretoras</option>
            {broker && broker.map((item: any, index: any) => (
              <option value={item} key={index}>{item}</option>
              ))}
          </select>
          <select 
            name="todosPares" 
            id="todosPares"
            onChange={(ev) => handleFilter('moeda', ev.target.value)}
            >
            <option value="todosPares">Todos os Pares</option>
            {coin && coin.map((item: any, index: any) => (
              <option value={item} key={index}>{item}</option>
            ))}
          </select>
        </Row>
        {openOrders && 
          <Row wide={"60%"} className="filters">
            <Button onClick={() => handleModal()}>Nova Ordem</Button>
            <Button>Vender</Button>
            {/* <Button>Ativar</Button> */}
            <Button>Pausar</Button>
          </Row>
        }
      </DivCard>

      <Content>
        <ListTable
          multipleSelect={openOrders ? multipleSelect : false}
          changeSelectedIndexes={setSelectedIndexes}
          fields={openOrders ? cols : colsOrdersClosed}
          data={openOrders && orders ? orders.docs : !openOrders && ordersClosed ? ordersClosed.docs : []}
          onChangePage={handleChangePage}
          currentPage={openOrders ? currentPage : currentPageClosed}
          totalPages={openOrders && orders ? orders.totalPages : !openOrders && ordersClosed ? ordersClosed.totalPages : 1}
          count={openOrders && orders ? orders.total : !openOrders && ordersClosed ? ordersClosed.total : 0}
          rowMenuOptions={actionsMenus}
          withFooter={false}
        />
      </Content>
      <Footer>
        <SelectedContainer>
          <p>Total: <b>{openOrders && orders ? orders.total : !openOrders && ordersClosed ? ordersClosed.total : 0}</b></p>
        </SelectedContainer>
        <PaginateContainer>
          <ReactPaginate
            breakLabel="..."
            nextLabel=" >"
            onPageChange={({ selected }) => handleChangePage(selected)}
            pageRangeDisplayed={5}
            pageCount={openOrders && orders ? orders.totalPages : !openOrders && ordersClosed ? ordersClosed.totalPages : 1}
            previousLabel="< "
            renderOnZeroPageCount={undefined}
            activeClassName='active-page-item'
          />
        </PaginateContainer>
      </Footer>
    </Container>
  );
};

export default Order;
