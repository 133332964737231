import React, { useState } from 'react';
import { useFormik, FormikHelpers } from 'formik';
import { useHistory } from 'react-router-dom';

import { Container, Form, Input, Button, Link, FormContainer, Logo, Title, Subtitle, Row, ColumnInput } from './styles';

import LogoImage from '../../assets/images/logo_rizar.png';
import { useAuth, ISignInPayload } from '../../contexts/auth';
import { MaskInput } from '../../components/Input/styles';
import { AiOutlineEye, AiOutlineEyeInvisible, AiOutlineLoading } from 'react-icons/ai';
import { postRegister } from '../../services/register';
import toast from 'react-hot-toast';

interface IRegisterValues {
  nome?: string;
  email?: string;
  telefone?: string;
  senha?: string;
  confirm_password?: string;
}

const Register: React.FC = () => {
  const { signIn } = useAuth();
  const history = useHistory();
  const [password, setPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const initialValues: IRegisterValues = {
    nome: '',
    email: '',
    telefone: '',
    senha: '',
    confirm_password: '',
  };

  const handleSubmit = async (values: IRegisterValues, helpers: FormikHelpers<IRegisterValues>) => {
    setLoading(true)
    try {
      const {nome, email, telefone, senha} = values
      const result = await postRegister({nome, email, telefone, senha})
      if(result.status === 201){
        toast.success('Dados enviados com sucesso.')
        toast.success('Verifique seu e-mail.')
        history.push('/login')
      }
    } catch (error) {
      console.error('Erro: ', error)
    }
    setLoading(false)
    // signIn(values);
    // helpers.setSubmitting(false);
  }


  const validate = (values: IRegisterValues) => {
    const errors: IRegisterValues = {};

    if(!values.nome){
      errors.nome = '* Obrigatório';
    }

    if (!values.email) {
      errors.email = '* Obrigatório';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Este endereço de e-mail é inválido';
    }

    if(!values.telefone){
      errors.telefone = '* Obrigatório'
    }

    if (!values.senha) {
      errors.senha = '* Obrigatório'
    } else if (values.senha.length < 8) {
      errors.senha = 'A senha precisa ter no mínimo 8 dígitos'
    }

    if(!values.confirm_password){
      errors.confirm_password = '* Obrigatório'
    } else if (values.confirm_password !== values.senha){
      errors.confirm_password = 'O campo confirmar senha precisa ser igual a senha.'
    }

    return errors;
  };

  const registerForm = useFormik({
    initialValues,
    validate,
    validateOnChange: false,
    onSubmit: handleSubmit,
  });

  return (
    <Container>
      <FormContainer>
        <Logo src={LogoImage} />
        <Title>Cadastre-se no Rizar</Title>
        <Subtitle>Crie sua conta e automatize suas compras em cripto.</Subtitle>
        <Form onSubmit={registerForm.handleSubmit} autoComplete="off">
          <Input
            id="nome"
            name="nome"
            placeholder="Digite seu nome..."
            label="Nome"
            value={registerForm.values.nome}
            error={registerForm.errors.nome}
            onChange={registerForm.handleChange}
          />
          <Input
            id="email"
            name="email"
            placeholder="Digite seu e-mail..."
            label="E-mail"
            value={registerForm.values.email}
            error={registerForm.errors.email}
            onChange={registerForm.handleChange}
          />
          <Input
            id="telefone"
            name="telefone"
            masked={true}
            mask={'(99) 99999-9999'}
            placeholder="Digite seu telefone..."
            label="Telefone"
            value={registerForm.values.telefone}
            error={registerForm.errors.telefone}
            onChange={registerForm.handleChange}
          />
          <Row>
            <ColumnInput>
              <button className='password' type='button' onClick={() => setPassword(!password)}>
                {password ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
              </button>
              <Input
                wide='100%'
                id="senha"
                name="senha"
                placeholder="Digite sua senha..."
                label="Senha"
                type={password ? "text" : "password"}
                value={registerForm.values.senha}
                error={registerForm.errors.senha}
                onChange={registerForm.handleChange}
              />
            </ColumnInput>
            <ColumnInput>
              <button className='confirmPassword' type='button' onClick={() => setConfirmPassword(!confirmPassword)}>
                {confirmPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
              </button>
              <Input
                wide='100%'
                id="confirm_password"
                name="confirm_password"
                placeholder="Digite sua senha novamente..."
                label="Confirmação de senha"
                type={confirmPassword ? "text" : "password"}
                value={registerForm.values.confirm_password}
                error={registerForm.errors.confirm_password}
                onChange={registerForm.handleChange}
              />
            </ColumnInput>
          </Row>
          <Button disabled={registerForm.isSubmitting} variant="filled" type="submit">
            Cadastrar &nbsp;
            {loading && <span><AiOutlineLoading /></span>}
          </Button>
          <Link variant="outlined" to={{ pathname: '/login' }}>Voltar ao Login</Link>
        </Form>
      </FormContainer>
    </Container>
  );
}

export default Register;