/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import {getYear, getMonth, format, parse, getDate, isValid, add, sub} from 'date-fns';
import parseDate from 'date-fns/parse';
import {ptBR} from 'date-fns/locale';
import isDateValid from 'date-fns/isValid';
import { DateMaskInput, Wrapper, Label, Input } from './styles';

import { isValidISODate, convertToDate } from '../../utils/date';
import Toast from '../Toast';

function range(start: number, end: number) {
    return Array(end - start + 1).fill(null).map((_, idx) => start + idx)
}

interface IDateInputProps {
    id: string;
    name: string;
    label: string;
    value: any;
    withHour: boolean;
    onChange(event: any): void;
    require?: boolean;
    inTable?: boolean;
    disabled?: boolean;
    error?: boolean;
}

const DateInput: React.FC<IDateInputProps> = ({
  id,
  name,
  label,
  value,
  withHour,
  onChange,
  inTable,
  disabled,
  require,
  error
}) => {
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [hasRendered, setHasRendered] = useState(false);
    const [currentDateMaskValue, setCurrentDateMaskValue] = useState('');
    const [prevKeys, setPrevKeys] = useState('');
    const [enableMask, setEnableMask] = useState(true);

    useEffect(() => {
      if (startDate && typeof startDate !== 'string') {
        const isoDate = new Date(startDate.toISOString());
        if (isValidISODate(isoDate)) {

            if (withHour) {
                const formatedStartDate = format(isoDate, 'dd/MM/yyyy HH:mm');
                onChange({
                    target: {
                        id,
                        name,
                        value: formatedStartDate,
                    },
                });
                dateFormatInput(startDate);
            } else {
                onChange({
                    target: {
                        id,
                        name,
                        value: format(isoDate, 'dd/MM/yyyy')
                    },
                });
                dateFormatInput(startDate);
            }

        }

      } else {
        onChange({
            target: {
                id,
                name,
                value: null,
            },
        });
      }
    }, [startDate]);

    useEffect(() => {
        if (value && !hasRendered) {
            let testDate = new Date();
            const formatDateStr = value.split(' ').length > 1 ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy';
            testDate = parseDate(value, formatDateStr, new Date(), {locale: ptBR});
            
            
            if (isDateValid(testDate)) {
                setStartDate(testDate);
            } else if (isDateValid(new Date(value))) {
                const _date = new Date(value);
                setStartDate(_date);
            } else {
                const checkDate = convertToDate(value);

                if (checkDate) {
                    setStartDate(checkDate);
                }
            }
            
            setHasRendered(true);
        }
    }, [value, hasRendered]);

    const handleChangeInputMask = (event: React.FormEvent<HTMLInputElement>) => {
        const { currentTarget: {value} } = event;
        
        if(enableMask) {
            const currentDate = new Date();
            const currentMonth = getMonth(currentDate) + 1;
            const currentYear = getYear(currentDate);
            
    
            let day;
            let month;
            let date;
            //removendo mascara do input 
            const rawValue = value.replace(/[^\d]/g, '');
    
            //verifica se digitou so dias
            if(rawValue.length > 0 && rawValue.length <=2) {
                day = rawValue;
                const dateStr = `${currentYear}-${currentMonth}-${day}`;
                
                date = parse(dateStr, 'yyyy-MM-dd', new Date);
                if(isValid(date)) {
                    setStartDate(date);
                    dateFormatInput(date);
                } else {
                    Toast.show("Digite uma data válida");
                }
                
            } else if(rawValue.length > 0 && rawValue.length <= 4) {
                day = rawValue.substring(0, 2);
                month = rawValue.substring(2, 4);
                const dateStr = `${currentYear}-${month}-${day}`;
    
                date = parse(dateStr, 'yyyy-MM-dd', new Date);
                if(isValid(date)) {
                    setStartDate(date);
                    dateFormatInput(date);
                } else {
                    Toast.show("Digite uma data válida");
                }
            } else if(rawValue.length === 8) {
                day = rawValue.substring(0, 2);
                month = rawValue.substring(2, 4);
                const year = rawValue.substring(4, 8);
                const dateStr = `${year}-${month}-${day}`;
                date = parse(dateStr, 'yyyy-MM-dd', new Date);
                if(isValid(date)) {
                    setStartDate(date);
                    dateFormatInput(date);
                } else {
                    Toast.show("Digite uma data válida");
                }
            }
        } else {
            if(value.includes("+")) {
                const days = value.replaceAll("+", "");
                const newDate = add(new Date(), {days: Number.parseInt(days)});
                newDate.setHours(0, 0, 0);

                if(isValid(newDate)) {
                    setStartDate(newDate);
                    dateFormatInput(newDate);
                }

            } else if(value.includes("-")) {
                const days = value.replaceAll("-", "");
                const newDate = sub(new Date(), {days: Number.parseInt(days)});
                newDate.setHours(0, 0, 0);
                
                if(isValid(newDate)) {
                    setStartDate(newDate);
                    dateFormatInput(newDate);
                }
            }
            setEnableMask(true);
        }

    }

    const handleShortcutData = (event: any) => {
        const {key} = event;
        
        if(key === '+' || key === '-') {
            setEnableMask(false);
            setCurrentDateMaskValue('');
        }

        if(key === 'h') {
            const _date = new Date();
            if(isValid(_date)) {
                setStartDate(_date);
                toNextInput(event);
            }
            
        } else if(key === 'a') {
            const _date = add(new Date(), {days: 1});
            if(isValid(_date)) {
                setStartDate(_date);
                toNextInput(event);
            }

        } else if(key === 'o') {
            const _date = add(new Date(), {days: -1});
            if(isValid(_date)) {
                setStartDate(_date);
                toNextInput(event);
            }
        }
    }

    const toNextInput = (event: any) => {
        const form = event.target.form;
        const index = [...form].indexOf(event.target);
        if(index + 1) {
            //adicionando um delay para evitar que a troca de focus seja feita antes do state mudar
            setTimeout(() => form.elements[index + 1].focus(), 150);
        }
    }

    const handleOnFocus = (event: any) => {
        event.target.select();
        setPrevKeys('');
    }

    const dateFormatInput = (date: any) => {
        const _day = getDate(date) < 10 ? '0'+getDate(date) : getDate(date);
        const _month = (getMonth(date)+1) < 10 ? '0'+(getMonth(date)+1) : (getMonth(date)+1);

        const strStateDate = `${_day}${_month}${getYear(date)}`;
        setCurrentDateMaskValue(strStateDate);
    }

    const resetCurrentDateMask = (event: React.FormEvent<HTMLInputElement>) => {
        const { currentTarget: {value} } = event;
        
        setCurrentDateMaskValue(value);
    }
    
    return (
        <Wrapper>
            {!inTable && (<Label error={error ? true : false} bold={require}>{label} {require ? <span>*</span>:null}</Label>)}
            <DateMaskInput 
            value={currentDateMaskValue}
            disabled={disabled}
            mask={enableMask ? "99/99/9999" : ""}
            onBlur={handleChangeInputMask}
            onFocus={handleOnFocus}
            onChange={resetCurrentDateMask}
            onKeyDown={handleShortcutData}
            />
            {/* <DatePicker
                customInput={<CustomInput />} 2022-04-29T03:00:00.000Z
                renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
                }) => (
                <Container>
                    <button type="button" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                    {"<"}
                    </button>
                    <select
                    value={getYear(date)}
                    onChange={({ target: { value } }) => changeYear(parseInt(value))}
                    >
                    {years.map((option) => (
                        <option key={option} value={option}>
                        {option}
                        </option>
                    ))}
                    </select>
        
                    <select
                    value={months[getMonth(date)]}
                    onChange={({ target: { value } }) =>
                        changeMonth(months.indexOf(value))
                    }
                    >
                    {months.map((option) => (
                        <option key={option} value={option}>
                        {option}
                        </option>
                    ))}
                    </select>
        
                    <button type="button" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                    {">"}
                    </button>
                </Container>
                )}
                id={id}
                name={name}
                locale="pt-BR"
                dateFormat={withHour ? "dd/MM/yyyy HH:mm" : "dd/MM/yyyy"}
                timeCaption='Hora'
                timeFormat='HH:mm'
                showTimeSelect={withHour}
                selected={startDate}
                disabled={disabled}
                onChange={(date) => setStartDate(date)} 
            />*/}
        </Wrapper>
    );
}

export default DateInput;