/* eslint-disable react-hooks/exhaustive-deps */
import React, { InputHTMLAttributes, useState, useEffect } from 'react';
import { Wrapper, Label, Input, MaskInput, Check, CurrencyInput } from './styles';
interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  label: string;
  className?: string;
  error?: string;
  readonly?: boolean;
  width?: 'xsm' | 'sm' | 'md' | 'lg' | 'xlg';
  fullWidth?: boolean;
  masked?: boolean;
  money?: boolean;
  require?: boolean;
  mask?: string | (string | RegExp)[];
  onChange?(target: any): void;
  inTable?: boolean;
  decimalPrecision?: number;
  coin?: string;
  fixed?: boolean;
  isAddress?: boolean;
  disabled?: boolean;
}

const TextInput: React.FC<IInputProps> = ({
  id,
  label,
  className,
  error,
  readonly = false,
  width = "md",
  fullWidth = false,
  masked,
  type,
  mask = '',
  money,
  decimalPrecision,
  inTable,
  fixed,
  isAddress,
  require,
  ...props
}) => {
  const [value, setValue] = useState<any>(props.value);
  const [hasRendered, setHasRendered] = useState(false);

  useEffect(() => {
    if (props.onChange) {
      if (value !== undefined) {
        props.onChange({
          target: {
              id,
              name: props.name,
              value,
          },
        });
      }
    }
  }, [value]);

  useEffect(() => {
    if (props.value !== null && props.value !== undefined && (!hasRendered || isAddress)) {
      setHasRendered(true);
      setValue(props.value);
    }
  }, [hasRendered, props.value, isAddress]);

  useEffect(() => {
    if (fixed && props.value) {
      setValue(props.value);
    }
  }, [props.value, fixed]);

  function cpfCnpjMask() {
    if (!value || value.length === 0) 
      return "999999999999999999";

    if (value.replace(/\D/g, "").length < 12) {
      return "999.999.999-999999";
     } else {
      return "99.999.999/9999-99";
    }
  }
  
  return (
    <Wrapper
      className={className} row={type === 'checkbox'}>
      {type === 'checkbox' && (
        <Check 
          id={id}
          {...props}
          checked={value}
          onChange={({target}) => setValue(target.checked)}
          width={width}
          error={error}
        />
      )}
      {!inTable && (
        <Label error={error ? true : false} bold={require} htmlFor={id}>
          {label} {
            error ? (
            <span>(* {error})</span>
            ) : require ? <span>*</span> : null
          }
        </Label>
      )}
      {type !== 'checkbox' && money && masked && (
        <CurrencyInput
          id={id}
          {...props}
          value={value}
          onChange={(_event: any, maskedValue: any) => setValue(maskedValue)}
          width={width}
          precision={decimalPrecision}
          error={error}
          mask={mask}
        />
      )}
      {type !== 'checkbox' && !money && masked && mask === 'integer' && (
        <Input 
          id={id}
          type={type ? type : 'text'}
          {...props}
          value={String(value) !== 'undefined' && String(value) !== 'NaN' && String(value) !== 'null' ? String(value) : ''}
          onChange={({target}) => setValue(parseInt(target.value))}
          width={width}
          error={error}
        />
      )}
      {type !== 'checkbox' && !money && masked && mask !== 'integer' && (
        <MaskInput
          id={id}
          type={type ? type : 'text'}
          {...props}
          value={value}
          onChange={({target}) => setValue(target.value)}
          width={width}
          error={error}
          mask={id === 'cpfCnpj' ? cpfCnpjMask() : mask}
        />
      )}
      {type !== 'checkbox' && !money && !masked && (
        <Input 
          id={id}
          type={type ? type : 'text'}
          {...props}
          value={value}
          onChange={({target}) => setValue(target.value)}
          width={width}
          error={error}
        />
      )}
    </Wrapper>
  );
}

export default TextInput;