import Provider from './config'

export const getFieldsUser = async () => {
  try {
    const result = await Provider.get('entidades/campos', {
      params: {
        classe: 'usuários'
      }
    })

    return result.data
  } catch (err: any) {
    throw Error ('Erro ao buscar campos da tabela de usuários.')
  }
}

export const getUsers = async (currentPage: number) => {
  try {
    const result = await Provider.get('entidades', {
      params: {
        page: currentPage,
        classe: 'usuários'
      }
    })

    return result.data
  } catch (err) {
    throw Error('Erro ao buscar usuários.')
  }
}