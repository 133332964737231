import React, { useEffect, useState } from "react";
import ListTable, { IField } from "../../components/ListTable";
import { IToggleMenuOption } from "../../components/ToggleMenuButton";
import { useAuth } from "../../contexts/auth";
import { getFields, getOpenOrders } from "../../services/orders";
import { orderCols } from "../../utils/form";
import { Container, Content, DivCard, Row, Title } from "./style";

interface IFinancial {
  title: string;
  multipleSelect: boolean;
}

interface IResponse {
  docs: any[];
  totalPages: number;
  total: number;
}

const Financial: React.FC<IFinancial> = ({ title, multipleSelect }) => {
  const { user } = useAuth();
  const [finished, setFinished] = useState(false);
  const [pending, setPending] = useState<IResponse | null>(null)
  const [selectedIndexes, setSelectedIndexes] = useState<boolean[]>([]);
  const [cols, setCols] = useState<Array<IField>>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [actionsMenus, setActionsMenus] = useState<IToggleMenuOption[]>([]);

  useEffect(() => {
    getTableFilds();
  }, [])

  const getTableFilds = async () => {
    try {
      const result = await getFields(user?.id);
      if (result) {
        const order_cols = result.sort(orderCols);
        setCols(order_cols);

        const resultOrders = await getOpenOrders(currentPage, user?.id);
        if (resultOrders) {
          setPending(resultOrders);
        }
      }
    } catch (error) {
      console.error("Erro: ", error);
    }
  };

  const handleChangePage = (page: number) => {
    if (page + 1 !== currentPage) {
      setCurrentPage(page + 1);
    }
  };

  return (
    <Container>
      <Title>{title}</Title>

      <DivCard>
        <Row>
          <button
            className="buttonOrder"
            type="button"
            onClick={() => setFinished(false)}
          >
            <p className={!finished ? "primary" : ""}>Pendentes</p>
          </button>
          <button
            className="buttonOrder"
            type="button"
            onClick={() => setFinished(true)}
          >
            <p className={finished ? "primary" : ""}>Realizados</p>
          </button>
        </Row>
      </DivCard>

      <Content>
        <ListTable
          multipleSelect={multipleSelect}
          changeSelectedIndexes={setSelectedIndexes}
          fields={cols}
          data={pending ? pending.docs : []}
          onChangePage={handleChangePage}
          currentPage={currentPage}
          totalPages={pending ? pending.totalPages : 1}
          count={pending ? pending.total : 0}
          rowMenuOptions={actionsMenus}
          withFooter={false}
        />
      </Content>
    </Container>
  );
};

export default Financial;
