import React, { useEffect, useState } from "react";
import {
  Button,
  Column,
  Container,
  Content,
  DivCard,
  DivColumn,
  DivLabel,
  DivRow,
  Row,
  Select,
  Title,
} from "./styles";
import {
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineLoading,
} from "react-icons/ai";
import DualListBox from "react-dual-listbox";
import {
  getWhiteList,
  getWhiteListFilter,
  postWhiteList,
  getWhiteListSelected,
  getFieldWhiteList,
} from "../../services/whiteList";
import { BsCheckLg } from "react-icons/bs";
import toast from "react-hot-toast";
import { IResponse } from "../Orders";
import ListTable, { IField } from "../../components/ListTable";
import { IToggleMenuOption } from "../../components/ToggleMenuButton";
import { orderCols } from "../../utils/form";
import { Footer, PaginateContainer, SelectedContainer } from "../Orders/style";
import ReactPaginate from "react-paginate";

interface IFilters {
  marketCap: number;
  volume: number;
  supply: number;
}

interface IWhiteList {
  title: string;
  multipleSelect: boolean;
}

interface ICoin {
  id: string;
  value: string;
  label: string;
}

const Whitelist: React.FC<IWhiteList> = ({ title, multipleSelect }) => {
  const [firstSelected, setFirstSelected] = useState([]); // recebe o valor inicial da white list que vem do back
  const [availableCurrencies, setAvailableCurrencies] =
    useState<IResponse | null>(null); // moedas disponíveis
  const [whiteList, setWhiteList] = useState<IResponse | null>(null); // moedas selecionadas
  const [selectedIndexes, setSelectedIndexes] = useState<boolean[]>([]);
  const [available, setAvailable] = useState(true); // responsável por verificar qual aba está em uso
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageClosed, setCurrentPageClosed] = useState(1);
  const [actionsMenus, setActionsMenus] = useState<IToggleMenuOption[]>([]);
  const [cols, setCols] = useState<Array<IField>>([
    // {
    //   campo: "Pessoa",
    //   classe: "Pessoa",
    //   editavel: false,
    //   nome: "Moeda",
    //   required: true,
    //   tipo: "lookup",
    // },
    // {
    //   campo: "Mercado",
    //   classe: "Pessoa",
    //   mascara: "dinheiro",
    //   editavel: false,
    //   required: true,
    //   nome: "Valor Mercado",
    //   tipo: "texto",
    // },
    // {
    //   campo: "Volume",
    //   classe: "Pessoa",
    //   editavel: false,
    //   required: true,
    //   nome: "Volume USDT 24H",
    //   tipo: "texto",
    // },
    // {
    //   campo: "Fornecimento",
    //   classe: "Pessoa",
    //   editavel: false,
    //   required: true,
    //   nome: "Fornecimento",
    //   tipo: "texto",
    // },
    // {
    //   campo: "Preco",
    //   classe: "Pessoa",
    //   editavel: false,
    //   required: true,
    //   nome: "Variação de Preço(24h)",
    //   tipo: "texto",
    // },
  ]);
  const [optionInitialValue, setOptionInitialValue] = useState("K");
  const [optionMarketValue, setOptionMarketValue] = useState("K");
  const [optionSupplyValue, setOptionSupplyValue] = useState("K");
  const [loading, setLoading] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [initialValues, setInitialValues] = useState<IFilters>({
    marketCap: 0,
    volume: 0,
    supply: 0,
  });

  useEffect(() => {
    // getWhiteListAll();
    getTableFields();
  }, [currentPage]);
  
  useEffect(() => {
    getCoinSelected();
  }, [currentPageClosed])

  // function handleSelected(value: any) {
  //   setSelected(value);
  // }

  function handleValues(key: string, value: any) {
    if (key === "optionInitialValue") {
      setOptionInitialValue(value);
    } else if (key === "optionMarketValue") {
      setOptionMarketValue(value);
    } else if (key === "optionSupplyValue") {
      setOptionSupplyValue(value);
    } else {
      setInitialValues({
        ...initialValues,
        [key]: value,
      });
    }
  }

  const validateFilter = () => {
    if (
      initialValues.volume <= 0 &&
      initialValues.marketCap <= 0 &&
      initialValues.supply <= 0
    ) {
      toast.error("Informe um valor para filtrar.");
    } else {
      return true;
    }
  };

  // Função responsável por verificar se os itens selecionados está diferente dos itens de início para enviar somente o id dos que estiverem direfentes
  const handleId = () => {
    let itens: any = [];

    if (whiteList) {
      const first = firstSelected.map((item: any) => item.id);
      const list = whiteList.docs.map((item: any) => item.id);
      if (firstSelected.length < whiteList?.docs.length) {
        for (let key of list) {
          if (firstSelected.length > 0) {
            if (!first.includes(key)) {
              itens.push(key);
            }
          } else if (firstSelected.length === 0) {
            itens.push(key.id);
          }
        }
      } else if (firstSelected.length > whiteList.docs.length) {
        for (let key of first) {
          if (!list.includes(key)) {
            itens.push(key);
          }
        }
      } else {
        for (let key of list){
          if(!first.includes(key)){
            itens.push(key)
          }
        }
        for (let _key of first){
          if(!list.includes(_key)){
            itens.push(_key)
          }
        }
      }
    }

    return itens;
  };

  const getTableFields = async () => {
    try {
      const result = await getFieldWhiteList();
      if (result) {
        const ordered = result.sort(orderCols);
        setCols(ordered);

        const resultWhiteList = await getWhiteList(currentPage);

        if (resultWhiteList) {
          setAvailableCurrencies(resultWhiteList);
        }
      }
    } catch (error) {
      toast.error("Erro ao buscar moedas disponíveis da white list.");
      console.error("Erro: ", error);
    }
  };

  const getCoinSelected = async () => {
    try {
      const result = await getWhiteListSelected(currentPageClosed);
      if (result) {
        const _result: any = [...result.docs];
        setFirstSelected(_result);
        setWhiteList(result);
      }
    } catch (error) {
      toast.error("Erro ao buscar moedas selecionadas da white list.");
    }
  };
  // const getWhiteListAll = async () => {
  //   try {
  //     const result = await getWhiteList();
  //     const resultSelect = await getWhiteListSelected();
  //     if (result) {

  //       const formattedOptions = result.Moedas.map((moeda: any) => {

  //         return {
  //           id: moeda.id,
  //           value: moeda.observacao + " " + moeda.coinInformations.volume,

  //           label: moeda.observacao + " " + moeda.coinInformations.volume,
  //         };
  //       });

  //       setOptions(formattedOptions);
  //     }
  //     if (resultSelect) {
  //       const formattedSelect = resultSelect.map(
  //         (moeda: any) => moeda.observacao + " " + moeda.coinInformations.volume
  //       );

  //       setSelected(formattedSelect);
  //       setFirstSelected(formattedSelect);
  //     }
  //   } catch (error) {
  //     console.error("Erro: ", error);
  //   }
  // };

  // const getFilterWhiteList = async () => {
  //   if (validateFilter()) {
  //     setLoadingFilter(true);
  //     if (
  //       initialValues.volume > 0 ||
  //       initialValues.marketCap > 0 ||
  //       initialValues.supply > 0
  //     ) {
  //       let _initialValues = formattedNewValue();

  //       try {
  //         const result = await getWhiteListFilter(_initialValues);

  //         if (result) {
  //           const formattedOptions = result.Moedas.map((moeda: any) => {
  //             return {
  //               id: moeda.id,
  //               value: moeda.observacao + " " + moeda.coinInformations.volume,

  //               label: moeda.observacao + " " + moeda.coinInformations.volume,
  //             };
  //           });

  //           setOptions(formattedOptions);
  //           setLoadingFilter(false);
  //         }
  //       } catch (error) {
  //         console.error("Erro: ", error);
  //       }
  //     }
  //     setLoadingFilter(false);
  //   }
  // };

  const formattedNewValue = () => {
    const _initialValues = { ...initialValues };
    if (initialValues.volume > 0) {
      if (optionInitialValue === "K") {
        const newValue = initialValues.volume * 1000;
        _initialValues.volume = newValue;
      } else if (optionInitialValue === "M") {
        const newValue = initialValues.volume * 1000000;
        _initialValues.volume = newValue;
      } else if (optionInitialValue === "B") {
        const newValue = initialValues.volume * 1000000000;
        _initialValues.volume = newValue;
      }
    }
    if (initialValues.marketCap > 0) {
      if (optionMarketValue === "K") {
        const newValue = initialValues.marketCap * 1000;
        _initialValues.marketCap = newValue;
      } else if (optionMarketValue === "M") {
        const newValue = initialValues.marketCap * 1000000;
        _initialValues.marketCap = newValue;
      } else if (optionMarketValue === "B") {
        const newValue = initialValues.marketCap * 1000000000;
        _initialValues.marketCap = newValue;
      }
    }
    if (initialValues.supply > 0) {
      if (optionSupplyValue === "K") {
        const newValue = initialValues.supply * 1000;
        _initialValues.supply = newValue;
      } else if (optionSupplyValue === "M") {
        const newValue = initialValues.supply * 1000000;
        _initialValues.supply = newValue;
      } else if (optionSupplyValue === "B") {
        const newValue = initialValues.supply * 1000000000;
        _initialValues.supply = newValue;
      }
    }
    return _initialValues;
  };

  const handleSubmit = async () => {
    const idsSelected = handleId();
    setLoading(true);
    if (idsSelected.length > 0) {
      try {
        const result = await postWhiteList(idsSelected);
        if (result.status === 201) {
          await getCoinSelected();
          toast.success("Salvo com sucesso!");
          setLoading(false);
        }
      } catch (error) {
        console.error("Erro: ", error);
      }
    }
    setLoading(false);
  };

  const handleChangePage = (page: number) => {
    if (available) {
      if (page + 1 !== currentPage) {
        setCurrentPage(page + 1);
      }
    } else {
      if (page + 1 !== currentPageClosed) {
        setCurrentPageClosed(page + 1);
      }
    }
  };
  
  // Função responsável por retirar o item selecionado da lista de moedas disponíveis
  const handleChangeWhiteList = () => {
    const itens: any = [];
    const indexItem: any = [];
    if (availableCurrencies) {
      let docs = [...availableCurrencies?.docs];
      let total = availableCurrencies?.total;
      let totalPages = availableCurrencies.totalPages;
      let selection = [...selectedIndexes];

      selectedIndexes.map((item: any, index: any) => {
        if (item == true) {
          itens.push(availableCurrencies?.docs[index]);
          selection.splice(index, 1);
        }
      });

      itens.map((item: any) => {
        let itemIndex = docs.findIndex((i: any) => i === item)
        if(itemIndex >= 0){
          docs.splice(itemIndex, 1)
        }
      })

      total = docs.length;

      setAvailableCurrencies({ docs, total, totalPages });

      setSelectedIndexes(selection)

      addWhiteList(itens);
    }
  };

  // Função responsável por adicionar na white list os itens que recebe por parâmetro
  const addWhiteList = (itens: any) => {
    if (whiteList) {
      let docs = [...whiteList?.docs];
      let total = whiteList?.total;
      let totalPages = whiteList?.totalPages;

      itens.map((item: any) => {
        docs.push(item);
      });

      total += itens.length;

      
      setWhiteList({ docs, total, totalPages });
    }
  };
  // Função responsável por retirar itens da white list
  const handleWhiteListSelected = () => {
    if (whiteList) {
      const itens: any = [];
      let docs = [...whiteList?.docs];
      let total = whiteList?.total;
      let totalPages = whiteList.totalPages;
      let selection = [...selectedIndexes];

      selectedIndexes.map((item: any, index: any) => {
        if (item === true) {
          itens.push(docs[index]);
          selection.splice(index, 1);
        }
      });


      itens.map((item: any) => {
        let itemIndex = docs.findIndex((i: any) => i === item)
        if(itemIndex >= 0){
          docs.splice(itemIndex, 1)
        }
      })

      total = docs.length;

      setWhiteList({ docs, total, totalPages });
      
      setSelectedIndexes(selection)

      addAvailableCurrencies(itens);
    }
  };

  // Função responsável por adicionar em moedas disponíveis os itens que recebe por parâmetro
  const addAvailableCurrencies = (itens: any) => {
    if (availableCurrencies) {
      let docs = [...availableCurrencies?.docs];
      let total = availableCurrencies?.total;
      let totalPages = availableCurrencies?.totalPages;

      itens.map((item: any) => {
        docs.push(item);
      });

      total += itens.length;

      setAvailableCurrencies({ docs, total, totalPages });
    }
  };

  return (
    <Container>
      <Title>{title}</Title>

      <DivCard>
        <DivRow>
          <button
            className="buttonOrder"
            type="button"
            onClick={() => setAvailable(true)}
          >
            <p className={available ? "primary" : ""}>
              Moedas disponíveis
              {availableCurrencies ? `(${availableCurrencies.total})` : "(0)"}
            </p>
          </button>
          <button
            className="buttonOrder"
            type="button"
            onClick={() => setAvailable(false)}
          >
            <p className={!available ? "primary" : ""}>
              Moedas Selecionadas
              {whiteList ? `(${whiteList.total})` : "(0)"}
            </p>
          </button>
        </DivRow>
      </DivCard>
      <Content>
        {/* <Row> Trecho de código comentado a pedido do cliente
          <Column>
            <div>
              <label>Volume:</label>
              <div>
                <input
                  type="number"
                  name="valor"
                  id="valor"
                  placeholder="1"
                  onChange={(ev) => handleValues("volume", ev.target.value)}
                />
                <Select
                  onChange={(ev) =>
                    handleValues("optionInitialValue", ev.target.value)
                  }
                >
                  <option value="K">K</option>
                  <option value="M">M</option>
                  <option value="B">B</option>
                </Select>
              </div>
            </div>
            <div>
              <label htmlFor="valor">Valor de Mercado:</label>
              <div>
                <input
                  type="number"
                  name="valor"
                  id="valor"
                  placeholder="1"
                  onChange={(ev) => handleValues("marketCap", ev.target.value)}
                />
                <Select
                  onChange={(ev) =>
                    handleValues("optionMarketValue", ev.target.value)
                  }
                >
                  <option value="K">K</option>
                  <option value="M">M</option>
                  <option value="B">B</option>
                </Select>
              </div>
            </div>
            <div>
              <label>Fornecimento em Circulação:</label>
              <div>
                <input
                  type="number"
                  name="valor"
                  id="valor"
                  placeholder="1"
                  onChange={(ev) => handleValues("supply", ev.target.value)}
                />
                <Select
                  onChange={(ev) =>
                    handleValues("optionSuppplyValue", ev.target.value)
                  }
                >
                  <option value="K">K</option>
                  <option value="M">M</option>
                  <option value="B">B</option>
                </Select>
              </div>
            </div>
          </Column>
          <button onClick={() => getFilterWhiteList()}>
            <BsCheckLg /> &nbsp;
            {loadingFilter && (
              <span className="loading">
                <AiOutlineLoading />
              </span>
            )}
          </button>
        </Row> */}

        <DivRow flex={"flex-end"}>
          <Button
            wide={"12%"}
            type="button"
            // disabled={!selectedIndexes}
            style={{ marginTop: 0, marginBottom: 0 }}
            onClick={() =>
              available ? handleChangeWhiteList() : handleWhiteListSelected()
            }
          >
            {available ? "Adicionar" : "Remover"}
          </Button>
        </DivRow>

        <Content pad={"20px 0 30px 0"}>
          <ListTable
            multipleSelect={multipleSelect}
            changeSelectedIndexes={setSelectedIndexes}
            fields={cols}
            data={
              available && availableCurrencies
                ? availableCurrencies?.docs
                : !available && whiteList
                ? whiteList?.docs
                : []
            }
            onChangePage={handleChangePage}
            currentPage={available ? currentPage : currentPageClosed}
            totalPages={
              available && availableCurrencies
                ? availableCurrencies.totalPages
                : !available && whiteList
                ? whiteList.totalPages
                : 1
            }
            rowMenuOptions={actionsMenus}
            withFooter={false}
          />
        </Content>
        <Footer>
          <SelectedContainer>
            <p>
              Total:{" "}
              <b>
                {available && availableCurrencies
                  ? availableCurrencies.total
                  : !available && whiteList
                  ? whiteList.total
                  : 0}
              </b>
            </p>
          </SelectedContainer>
          <PaginateContainer>
            <ReactPaginate
              breakLabel="..."
              nextLabel=" >"
              onPageChange={({ selected }) => handleChangePage(selected)}
              pageRangeDisplayed={5}
              pageCount={
                available && availableCurrencies
                  ? availableCurrencies.totalPages
                  : !available && whiteList
                  ? whiteList.totalPages
                  : 1
              }
              previousLabel="< "
              renderOnZeroPageCount={undefined}
              activeClassName="active-page-item"
            />
          </PaginateContainer>
        </Footer>

        <Button disabled={loading} onClick={() => handleSubmit()}>
          Salvar &nbsp;
          {loading && (
            <span>
              <AiOutlineLoading />
            </span>
          )}
        </Button>
      </Content>
    </Container>
  );
};

export default Whitelist;

// White list modelo anterior
{
  /* <DivColumn
          firstItem={options ? `"${options.length}"` : "0"}
          secondItem={selected ? `"${selected.length}"` : "0"}
        >
          <DivLabel>
            <div>
              <label>Moedas Disponíveis:</label>
            </div>
            <div>
              <label>Moedas Selecionadas:</label>
            </div>
          </DivLabel>

          <DualListBox
            options={options}
            selected={selected}
            canFilter
            onChange={(ev) => handleSelected(ev)}
            icons={{
              moveLeft: (
                <button className="button-list">
                  <AiOutlineArrowLeft />
                </button>
              ),
              moveRight: (
                <button className="button-list">
                  <AiOutlineArrowRight />
                </button>
              ),
            }}
          />

        </DivColumn> */
}
