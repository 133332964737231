import styled from 'styled-components';
import InputLayout from '../Input'
import ButtonLayout from '../Button';

interface IProfileSaveContainer {
  showing?: boolean;
}

interface IWidth {
  wide?: string;
  bottom?: string;
  margin?: string;
  colors?: string;
  transparent?: boolean;
}

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 0;
    background: transparent; 
  }
  
  &::-webkit-scrollbar-thumb {
    background: #FF0000;
  }

  @media(max-width: 1024px) {
    height: auto;
    overflow: hidden;
  }
`;

export const PaddingContainer = styled.div`
  padding: 16px;
`;

export const Content = styled.div`
  display: grid;
  height: 100%;
  grid-template-columns: 1fr 2fr;
  grid-gap: 8px;

  @media(max-width: 1024px) {
    grid-template-columns: 100%;
    margin-bottom: 64px;

    & > div {
      width: 100%;
    }
  }
`;

export const Title = styled.p`
  font-size: 24px;
  font-weight: 300;
  color: var(--white);
`;

export const ProfilePhotoContainer = styled.div`
  width: 350px;
  height: 300px;
  background-color: var(--form-background);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--primary-text-color);
  border-radius: 2px;
`; 

export const ProfilePhotoButton = styled.button`
  background-color: var(--white);
  border-radius: 50%;
  position: relative;
  transition: .2s;
  cursor: pointer;

  &:hover{
    & > div {
      opacity: 1;
    }
  }
`;

export const ProfilePhoto = styled.img`
  width: 250px;
  height: 250px;
  border-radius: 125px;
  object-fit: contain;
`;

export const ProfilePhotoButtonOverlay = styled.div`
  background-color: var(--white-opacity-background);
  border-radius: 125px;
  display: flex;
  height: 250px;
  justify-content: center;
  align-items: center;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: .2s;
  width: 250px;

  font-weight: 600;
  font-size: 12px;
  color: var(--primary-text-color);
`;

export const ProfileInfoContainer = styled.div`
  background-color: var(--form-background);
  border: 1px solid var(--primary-text-color);
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  padding: 16px;

  & > header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > p {
      color: var(--white);
      font-size: 18px;
      font-weight: 300;
    }

    & > button {
      background-color: transparent;
      color: var(--white);
      cursor: pointer;
      font-weight: 300;
    }
  }

  & > hr {
    border-bottom: 1px solid var(--primary-text-color);
    margin: 16px 0;
  }

  & > fieldset {
    margin-bottom: 8px;

    & > span {
      color: var(--muted);
      font-size: 14px;
      font-weight: 300;
    }

    & > p {
      color: var(--white);
    }
  }
`;

export const ProfileTokens = styled(ProfileInfoContainer)`
  margin-top: 6px;
  margin-left: auto;
  width: 100%;
`;

export const ProfileSaveContainer = styled.div<IProfileSaveContainer>`
  background-color: var(--form-background);
  /* position: absolute; */
  bottom: ${props => props.showing ? 0 : '-100vh'};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  border-top: 1px solid var(--primary-text-color);
  transition: .2s;

  & > button {
    width: 100px;

    &:last-child{
      background-color: transparent;
      color: var(--primary-text-color);
      margin-left: 8px;
    }
  }
`;

export const Form = styled.form``;

export const Row = styled.div<IWidth>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: ${props => props.bottom ? props.bottom : '32px'};

  & .signOut {
    background-color: transparent;
    transition: .2s;
    cursor: pointer;

    &:hover {
      opacity: .7;
    }
  }

  .green{
    color: var(--green);
  }

  .red{
    color: var(--warning);
  }

  & > .loading > svg{
    background: transparent;
    color: var(--primary);
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(359deg); }  
  }
`;

export const Column = styled.div<IWidth>`
  display: flex;
  flex-direction: column;
  width: ${props => props.wide ? props.wide : '100%'};

  .token{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
  }

  .d-flex{
    display: flex;
    width: 70%;
  }

  label{
    color: var(--white);
    margin-bottom: 4px;
  }
`;

export const RowInput = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  .finished{
    margin-top: 30px;
    width: 60%;
  }

  .finished > input{
    margin-right: 4px;
  }
`;

export const Input = styled.input<IWidth>`
  border-radius: 10px;
  background: ${props => props.transparent ? 'transparent' : 'var(--white)'};
  height: 40px;
  font-size: 16px;
  padding: 0px 8px 0px 8px;
  margin-right: 10px;
  width: ${props => props.wide ? props.wide : '100%'};
`;

export const Select = styled.select<IWidth>`
  border-radius: 10px;
  height: 40px;
  font-size: 16px;
  padding: 0px 8px 0px 8px;
  margin-right: 10px;
  width: ${props => props.wide ? props.wide : '100%'};
`;

export const Button = styled(ButtonLayout)<IWidth>`
  width: 100px;
  font-size: 14px;
  margin-left: ${props => props.margin ? props.margin : ''};
  `;

export const ButtonOutline = styled.button<IWidth>`
  background: transparent;
  border-radius: 10px;
  color: var(--white);
  cursor: pointer;
  font-size: 14px;
  font-weight: 300;
  height: 40px;
  margin-left: ${props => props.margin ? props.margin : ''};
  outline: 2px solid var(--primary);
  outline-offset: -2px;
  padding: 10px;
  text-transform: uppercase;
  width: ${props => props.wide ? props.wide : '100px'};
  white-space: nowrap;

  transition: .2s;
  -webkit-transition: .2s;

  :hover{
    background: var(--primary);
  }

`;

export const ButtonSvg = styled.button<IWidth>`

  & > svg{
    background: ${props => props.transparent ? 'transparent' : 'var(--white)'};
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    color: ${props => props.transparent ? 'var(--white)' : 'var(--black)'};
    display: ${props => props.transparent ? 'none' : 'block'};
    font-size: 40px;
    margin-left: -60px;
  } 
`;