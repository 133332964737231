import React, { useEffect, useState } from "react";
import { useFormModal } from "../../contexts/formModal";
import { getVideos } from "../../services/video";
import { orderCols } from "../../utils/form";
import { Title } from "../Home/styles";
import { Button } from "../Orders/style";
import NewVideoModal from "./NewVideoModal";
import {
  Card,
  Column,
  Container,
  Content,
  Description,
  IndexCard,
  Row,
  TitleCard,
} from "./style";

interface ITraining {
  title: string;
}

interface IVideo {
  codigo: string;
  quantidade: number;
  nome: string;
  observacao: string;
}

const Training: React.FC<ITraining> = ({ title }) => {
  const { openModal } = useFormModal();
  const [videos, setVideos] = useState<Array<IVideo>>([]);

  useEffect(() => {
    getVideo();
  }, []);

  useEffect(() => {
    if (videos) {
      const ordered = videos.sort(orderCols);

      setVideos(ordered);
    }
  }, [videos]);

  const getVideo = async () => {
    try {
      const result = await getVideos();
      if (result) {
        setVideos(result);
      }
    } catch (error) {
      console.error("Erro: ", error);
    }
  };

  const handleModal = () => {
    openModal({
      type: "confirm",
      title: "Novo Vídeo",
      children: () => (
        <NewVideoModal
          getVideo={getVideo}
          setVideos={(ev: any) => setVideos(ev)}
        />
      ),
    });
  };

  return (
    <Container>
      <Row>
        <Title>{title}</Title>
        <Button style={{ marginBottom: 16 }} onClick={() => handleModal()}>
          Novo Vídeo
        </Button>
      </Row>
      <Content>
        <Row>
          <Column wide={"30%"}>
            <h2>Por onde começar?</h2>
            <p className="subtitle">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt
              facilis cum blanditiis exercitationem saepe dolore natus.
            </p>
          </Column>
          <Column wide={"70%"} padd={"4px"}>
            {videos?.length > 0 ? (
              videos.map((item: any, index: any) => (
                <Card key={index}>
                  <Column wide={"50%"} padd={"20px"} jContent={"space-evenly"}>
                    <IndexCard>
                      <p>{item.quantidade}</p>
                    </IndexCard>
                    <TitleCard>{item.codigo}</TitleCard>
                    <Description>{item.observacao}</Description>
                  </Column>
                  <iframe
                    src={item.nome}
                    title={item.codigo}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  ></iframe>
                </Card>
              ))
            ) : (
              <Card>
                <Column wide={"50%"} padd={"20px"} jContent={"space-evenly"}>
                  <IndexCard>
                    <p>1</p>
                  </IndexCard>
                  <TitleCard>Criando uma conta</TitleCard>
                  <Description>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    Eos tenetur aut quidem beatae, exercitationem aspernatur
                    saepe similique itaque ullam accusantium laudantium iusto
                    voluptates, rem tempore cupiditate ipsum. Suscipit, libero
                    iste?
                  </Description>
                </Column>
                <iframe
                  src="https://www.youtube.com/embed/ZK9nLhgShyY"
                  title="Botpress solução completa - 01 Criar um bot e sua base de conhecimento"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                ></iframe>
              </Card>
            )}
          </Column>
        </Row>
      </Content>
    </Container>
  );
};

export default Training;
