import React, { useEffect } from 'react';
import { useFormModal } from '../../contexts/formModal';
import { IoClose } from 'react-icons/io5';
import { Container, Wrapper, Children, Content, Header, Actions, Button } from './styles';

type OnSubmitType = () => void;
type OnConfirmType = () => void;

export interface IModalProps {
  type: string;
  title: string;
  submitLabel: string;
  children(): React.ReactElement;
  onSubmit: OnSubmitType | null;
  onConfirm: OnConfirmType | null;
  onCancel?(): void;
  closeOnAction: boolean;
  props: any;
}

const FormModal: React.FC<IModalProps> = ({
  type,
  title,
  children,
  submitLabel,
  onConfirm,
  onSubmit,
  closeOnAction = true,
}) => {

  const {closeModal} = useFormModal();

  useEffect(() => {
    const close = (event: KeyboardEvent) => {
      if(event.code === 'Escape') {
        closeModal();
      }
    }

    document.addEventListener('keydown', close);

    return () => document.removeEventListener('keydown', close);
  }, [])

//   const handleConfirm = () => {
//     if (onConfirm !== null) {
//         onConfirm();
//     }

//     if (closeOnAction) {
//       closeModal();
//     }
//   }

//   const handleSubmit = () => {
//     if (onSubmit !== null) {
//       onSubmit();
//     }

//     if (closeOnAction) {
//       closeModal();
//     }
//   }

  return (
    <Container>
        <Wrapper onClick={closeModal} />
        <Content>
          <Header>
            <p>{title}</p>
            <IoClose onClick={closeModal} size={18} color="var(--white)"/> 
          </Header>
          <Children>
            {children()}
          </Children>
        </Content>
    </Container>
  );
}

export default FormModal;