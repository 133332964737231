import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import ListTable, { IField } from "../../components/ListTable";
import { getFieldsUser, getUsers } from "../../services/user";
import { orderCols } from "../../utils/form";
import { IResponse } from "../Orders";
import { Footer, PaginateContainer, SelectedContainer } from "../Orders/style";
import { Container, Content, Title } from "./style";

const User = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [cols, setCols] = useState<Array<IField>>([]);
  const [users, setUsers] = useState<IResponse | null>(null);
  const [selectedIndexes, setSelectedIndexes] = useState<boolean[]>([]);

  useEffect(() => {
    getTableFields();
  }, [currentPage]);

  const getTableFields = async () => {
    try {
      const result = await getFieldsUser();

      if (result) {
        const user = result.sort(orderCols);

        setCols(user);

        const resultUsers = await getUsers(currentPage);

        if (resultUsers) {
          setUsers(resultUsers);
        }
      }
    } catch (error) {
      console.error("Erro: ", error);
    }
  };

  const handleChangePage = (page: number) => {
    if (page + 1 !== currentPage) {
      setCurrentPage(page + 1);
    }
  };

  return (
    <Container>
      <Title>Usuários</Title>
      <Content>
        <ListTable
          data={users ? users.docs : []}
          fields={cols}
          changeSelectedIndexes={setSelectedIndexes}
          onChangePage={handleChangePage}
          currentPage={currentPage}
          totalPages={users ? users.totalPages : 1}
          count={users ? users.total : 0}
          withFooter={false}
        />
      </Content>
      <Footer>
        <SelectedContainer>
          <p>Total: <b>{users ? users.total : 0}</b></p>
        </SelectedContainer>
        <PaginateContainer>
          <ReactPaginate
            breakLabel="..."
            nextLabel=" >"
            onPageChange={({ selected }) => handleChangePage(selected)}
            pageRangeDisplayed={5}
            pageCount={users ? users.totalPages : 1}
            previousLabel="< "
            renderOnZeroPageCount={undefined}
            activeClassName='active-page-item'
          />
        </PaginateContainer>
      </Footer>
    </Container>
  );
};

export default User;
