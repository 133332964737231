import Provider from './config';

export const getWhiteListFilter = async (filter: any) => {
  try {
    const response = await Provider.get('exchanges/whitelist-coins', {
      params: {marketCap: filter.marketCap , volume: filter.volume, supply: filter.supply}
    })

    return response.data;
  } catch (err: any) {
    throw Error ('Erro ao buscar campos para o filtro white list.')
  }
}

export const getWhiteList = async (currentPage?: number) => {
  try {
    const response = await Provider.get('exchanges/whitelist-coins', {
      params: {
        page: currentPage,
        marketCap: 0 , 
        volume: 0, 
        supply: 0
      }
    })

    return response.data;
  } catch (err: any) {
    throw Error ('Erro ao buscar campos para white list.')
  }
}
export const getWhiteListOnlyNames = async (currentPage?: number) => {
  try {
    const response = await Provider.get('exchanges/whitelist-coins', {
      params: {allCoinsNames:true}
    })

    return response.data;
  } catch (err: any) {
    throw Error ('Erro ao buscar campos para white list.')
  }
}

export const getWhiteListSelected = async (currentPage: number) => {
  try {
    const response = await Provider.get('exchanges/whitelist-get-coins', {
      params: {
        page: currentPage,
        marketCap: 0, 
        volume: 0, 
        supply: 0
      }
    })

    return response.data
  } catch (err: any) {
    throw Error ('Erro ao buscar os campos para white list.')
  }
}

export const postWhiteList = async (ids: any) => {
  try {
    const response = await Provider.post('exchanges/whitelist-set-coins', {
      ids: ids
    })

    return response
  } catch (err: any) {
    throw Error ('Erro ao enviar dados do white list.')
  }
}

export const getFieldWhiteList = async () => {
  try {
    const result = await Provider.get('recursos/campos', {
      params: {
        classe: 'moedas'
      }
    })

    return result.data
  } catch (err: any) {
    throw Error ('Erro ao buscar campos da white list.')
  }
}