import provider from './config';

export const calcularPedido = async (payload: any): Promise<any> => {
    try {
      const response = await provider.post('/pedidos/calcular/', payload);
  
      return response.data;
    } catch (err: any) {
      throw Error("Ocorreu um erro ao tentar calcular o pedido");
    }
}

export const getTitulosCondicao = async (payload: any): Promise<any> => {
    try {
      const response = await provider.post('/pedidos/gerar-titulos-condicao/', payload);
  
      return response.data;
    } catch (err: any) {
      throw Error("Ocorreu um erro ao tentar gerar os títulos da condição de pagamento");
    }
}

export const criarPedido = async (payload: any): Promise<any> => {
  try {
    const response = await provider.post('/pedidos/', payload);

    return response.data;
  } catch (err: any) {
    throw Error("Ocorreu um erro ao tentar criar o pedido");
  }
}

export const updatePedido = async (id: number, payload: any): Promise<any> => {
  try {
    const response = await provider.put(`/pedidos/${id}`, payload);

    return response.data;
  } catch (err: any) {
    throw Error("Ocorreu um erro ao tentar atualizar o pedido");
  }
}

export const regraClasse = async (payload: any): Promise<any> => {
  try {
    const response = await provider.get('/tabela/sugestao-classe', {
      params: payload,
    });

    return response.data;
  } catch (err: any) {
    throw Error("Ocorreu um erro ao tentar buscar a regra de classe");
  }
}

export const regraNucleo = async (payload: any): Promise<any> => {
  try {
    const response = await provider.get('/tabela/sugestao-nucleo', {
      params: payload,
    });

    return response.data;
  } catch (err: any) {
    throw Error("Ocorreu um erro ao tentar buscar a regra de núcleo");
  }
}

export const regraPreco = async (payload: any): Promise<any> => {
  try {
    const response = await provider.get('/tabela/precificacao', {
      params: payload,
    });

    return response.data;
  } catch (err: any) {
    throw Error("Ocorreu um erro ao tentar buscar a regra de preço");
  }
}

export const getChavePedido = async (idUsuario: any): Promise<any> => {
  try {
    const response = await provider.get('/pedidos/chave-pedido', {
      params: {
        idUsuario,
      },
    });

    return response.data;
  } catch (err: any) {
    throw Error("Ocorreu um erro ao tentar buscar a chave de criação");
  }
}

export const checkChavesParaBaixa = async (chaves: number[]): Promise<any> => {
  try {
    const response = await provider.get('/pedidos/checar-baixas', {
      params: {
        chaves: chaves.join(','),
      },
    });

    return response.data;
  } catch (err: any) {
    throw Error("Ocorreu um erro ao tentar buscar as baixas dos items");
  }
}

export const checkIfPedidoHasBaixa = async (chaves: number[]): Promise<any> => {
  try {
    const response = await provider.get('/pedidos/checar-baixa-pedido-aprovado', {
      params: {
        chaves: chaves.join(','),
      },
    });

    return response.data;
  } catch (err: any) {
    throw Error("Ocorreu um erro ao verificar se o pedido tem baixa");
  }
}

export const getItemsAndTitulos = async (chaves: number[], all = false): Promise<any> => {
  try {
    const response = await provider.get('/pedidos/items-titulos', {
      params: {
        chaves: chaves.join(','),
        all,
      },
    });

    return response.data;
  } catch (err: any) {
    throw Error("Ocorreu um erro ao tentar buscar os items e títulos");
  }
}

export const getReport = async () => {
  try {
    const response = await provider.get('/pedidos/', {
      params: {
        page: 1,
        classe: 'Rendas',
        baixa: true
      }
    })

    return response.data
  } catch (err: any) {
    throw Error("Erro ao buscar os pedidos de saída.")
  }
}