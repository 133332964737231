import Provider from './config';

export const getBrokerAll = async () => {
  try {
    const result = await Provider.get('entidades/?classe=fornecedores')

    return result.data
  } catch (err: any) {
    throw Error ('Erro ao buscar as corretoras.')
  }
}

export const postTokens = async (id: any, payload: any) => {
  const idUser = id
  const satp = [
    {
      sptc: `${payload.broker}:${payload.api_key}:${payload.secret_key}`
    }
  ]

  try {
    const response = await Provider.post('keys-manager/', {idUser, satp})

    return response
  } catch (err: any) {
    throw Error ('Erro ao enviar tokens.')
  }
}