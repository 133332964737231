import Provider from './config'

export const postSupport = async (payload: any) => {
  try {
    const response = await Provider.post('', payload)

    return response
  } catch (err: any) {
    throw Error ('Erro ao enviar dados para o suporte')
  }
}