import React, { useEffect, useState } from 'react';
import { Container, Content, OpacityBackground, RoutesContainer } from './styles';

import Header from '../Header';
import LeftSidebar from '../LeftSidebar';
import RightSidebar from '../RightSidebar';
import Footer from '../Footer';

import AuthRoutes from '../../navigation/auth';
import { useMenu } from '../../contexts/menu';
import ModalMenu from '../LeftSidebar/modalMenu';

const Layout: React.FC = () => {

  const { isRightMenuOpen, initialRightMenu , isLeftMenuOpen: isExpanded, toggleRightMenu: toggleMenu } = useMenu();

  useEffect(() => {
    initialRightMenu()
  }, [])

  return (
    <Container>
      {isRightMenuOpen && <OpacityBackground  className='transition'/>}
      <Header />
      {isExpanded && <ModalMenu />}
      <Content>
        <LeftSidebar/>
        <RoutesContainer isExpanded={isExpanded}>
          <AuthRoutes />
        </RoutesContainer>
        <Footer />
      </Content>
      <RightSidebar />
    </Container>
  );
}

export default Layout;