import React from "react";

import Logo from "../../assets/images/logo.png"
import { Container, LinhaVertical, Link } from "./styles";

const Footer = () => {

  const date = new Date().getFullYear()

  return (
    <Container>
      <img src={Logo} alt="Logo Devari Tecnologia" />
      <LinhaVertical />
      &nbsp;<p>&copy;{date} Devari Tecnologia</p>
      {/* <Link href="#">Suporte</Link> */}
    </Container>
  )
}

export default Footer;