import React, { useState } from "react";
import { AiOutlineLoading } from "react-icons/ai";
import {
  Button,
  Container,
  Content,
  DivCard,
  DivZap,
  Image,
  Row,
  Title,
} from "./style";
import ImgZap from "../../assets/images/whatsapp.png";
import Toast from "../../components/Toast";
import { uploadFile } from "../../services/upload";
import toast from "react-hot-toast";
import { postSupport } from "../../services/support";

interface ISupport {
  title: string;
}

interface ISuport {
  assunto: string;
  descricao: string;
  anexo: string;
}

const Support: React.FC<ISupport> = ({ title }) => {
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState<ISuport>({
    assunto: "",
    descricao: "",
    anexo: "",
  });
  const [photo, setPhoto] = useState<any>({});

  const handleSubmit = async () => {
    if (handleValidate()) {
      setLoading(true);
      try {
        if (photo.anexo) {
          const keys = Object.keys(photo);

          for (const key of keys) {
            const value = photo[key];
            const formData = new FormData();
            formData.set("file", value);
            try {
              const url = await uploadFile(formData);
              // adjustedPayload[key] = url;
              if(url){
                const _payload = payload
                payload.anexo = url

                const result = await postSupport(_payload)
                if(result.status === 201){
                  toast.success("Dados enviados com sucesso.")
                  setLoading(false);
                }
              }
            } catch (err: any) {
              Toast.show(err.message, "error");
            }
          }
        } else {
          const result = await postSupport(payload);

          if (result.status === 201) {
            toast.success("Dados enviados com sucesso.");
            setLoading(false);
          }
        }
      } catch (error) {
        console.error("Erro: ", error);
        toast.error("Erro ao enviar dados para o suporte.");
        setLoading(false);
      }
    }
    setLoading(false);
  };

  const handlePayload = (key: any, value: string) => {
    setPayload({ ...payload, [key]: value });
  };

  const handlePhoto = (field: string, options: any) => {
    return setPhoto((prevState: any) => {
      return { ...prevState, [field]: options };
    });
  };

  const handleValidate = () => {
    if (payload.assunto === "") {
      toast.error("Selecione um assunto");
    } else if (payload.descricao === "") {
      toast.error("Adicione uma descrição.");
    } else {
      return true;
    }
  };

  return (
    <Container>
      <Title>{title}</Title>
      <DivCard>
        <h2>Enviar mensagem para o suporte</h2>
      </DivCard>
      <DivCard>
        <Row>
          <label htmlFor="Subject">Assunto</label><span>*</span>
          <select
            name="Subject"
            id="Subject"
            onChange={({ target }) => handlePayload("assunto", target.value)}
          >
            <option value="" hidden>
              Selecione...
            </option>
            <option value="conta">Conta</option>
            <option value="terminalNegociacao">Terminal de Negociação</option>
            <option value="provedor">Provedor de Serviço</option>
          </select>
        </Row>
      </DivCard>
      <Content>
        <label htmlFor="descriptin">Descrição</label><span>*</span>
        <textarea
          name="decription"
          id="description"
          onChange={({ target }) => handlePayload("descricao", target.value)}
        ></textarea>
      </Content>
      <Row jContent={"space-between"}>
        <div>
          <p>{photo.anexo ? photo.anexo : "Nenhum arquivo selecionado"}</p>
        </div>
        <div>
          {loading && (
            <span>
              <AiOutlineLoading />
            </span>
          )}
          <Button type="button">
            <label htmlFor="file">Anexo</label>
            <input
              type="file"
              id="file"
              accept=".png, .jpg, .jpeg"
              onChange={({ target }) => handlePhoto("anexo", target.value)}
            />
          </Button>
          <Button disabled={loading} onClick={() => handleSubmit()}>
            Enviar
          </Button>
        </div>
      </Row>

      <DivZap target="_blank" href="https://api.whatsapp.com/send?phone=">
        {/* <span>1</span> */}
        <Image src={ImgZap} alt="Icone do WhatsApp" />
      </DivZap>
    </Container>
  );
};

export default Support;
