import styled from 'styled-components';

interface ISkill {
  wide?: string;
}

export const Container = styled.div`
  padding: 16px 0;
  width: 100%;
  height: 100%;
`;

export const Title = styled.p`
  color: var(--black);
  font-size: 24px;
  margin-bottom: 16px;
`;

export const Content = styled.div`
  background: var(--form-background);
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 90%;
  padding: 16px;
  `;

export const Row = styled.div`
  border: 1px solid var(--primary-lighter);
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: fit-content;
  width: 100%;
`

export const Card = styled.div`
  /* background: #2d203d; */
  /* border: 6px solid transparent; */
  /* border-radius: 10px; */
  display: flex;
  flex-direction: column;
  height: 110px;
  margin: 8px;
  min-width: 140px;
  padding: 10px;
  width: auto;
  
  /* &:hover{
    box-shadow: 0 0 0.2em #848587;
    border: 6px solid var(--primary-lighter);
  } */

  h3{
    color: var(--black);
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
  }

  .content{
    display: flex;
    align-items: center;
    margin: 10px auto;
  }
  
  .content>p{
    font-size: 1.5rem;
    color: var(--black);
  }

  .green{
    color: var(--green) !important;
  }

  .red{
    color: var(--warning) !important;
  }

  .footer{
    display: flex;
    align-items: center;
  }

  .footer>p{
    color: var(--primary);
    font-size: 1rem;
    font-weight: 500;
    margin-left: 6px;
  }
  .item{
    background: #1E242C; //#31363B
    margin-top: 6px;
    width: 100%;
  }

  .skills{
    background: var(--primary-lighter);
    color: var(--white);
    margin-top: 2px;
    padding-top: 1px;
    padding-bottom: 1px;
    text-align: right;
  }

  .first{
    width: 85%;
  }

  .second{
    width: 80%;
  }

  .third{
    width: 65%;
  }

  .fourth{
    width: 82%;
  }
  
`

export const ItemSkill = styled.div<ISkill>`
  background: #1E242C; //#31363B
  margin-top: 6px;
  width: 100%;


  .skills{
    background: var(--primary-lighter);
    color: var(--white);
    margin-top: 2px;
    padding-top: 1px;
    padding-bottom: 1px;
    text-align: right;
  }

  .first{
    width: 85%;
  }

  .second{
    width: ${props => props.wide ? props.wide : '80%'};
  }

  .third{
    width: 65%;
  }

  .fourth{
    width: 82%;
  }
`;
