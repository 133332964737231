import styled from 'styled-components';

import ButtonLayout from '../Button';

export const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Wrapper = styled.button`
  position: absolute;
  background-color: var(--opacity-background);
  width: 100vw;
  height: 100vh;
  z-index: 1;
`;

export const Content = styled.div`
  background-color: var(--form-background);
  border: 1px solid var(--white);
  color: var(--white);
  border-radius: 4px;
  z-index: 2;
  width: 40vw;
  max-height: 90vh;
  overflow: scroll;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 8px 16px;
  background-color: var(--form-background);
  border-bottom: 1px solid var(--white);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  color: var(--white);

  & p {
    font-size: 18px;
    font-weight: 500;
  }

  svg {
    margin-top: 5px;
    cursor: pointer;
  }
`;

export const Actions = styled.div`
  padding: 8px 16px;
  border-top: 1px solid var(--opacity-background);

  display: flex;
  align-items: center;
  justify-content: flex-end;

  & button:nth-child(even) {
    color: var(--primary-text-color);
  }
`;

export const Button = styled(ButtonLayout)`
  width: 100px;
  font-size: 14px;
`;

export const Children = styled.div`
  padding: 8px 0;
  font-weight: 300;
`;