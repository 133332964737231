import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px 0;
  width: 100%;
`;

export const Title = styled.p`
  color: var(--black);
  font-size: 24px;
  margin-bottom: 16px;
  `;

export const Content = styled.div`
  background: var(--form-background);
  color: var(--black);
  overflow: auto;
  padding: 50px 16px 30px 16px;
`;