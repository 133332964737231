import { createGlobalStyle } from 'styled-components';
import { COLORS, IStoreColors } from '../environment/stores';

const formatColors = (colors: IStoreColors) => {
  let string = '';
  
  const keys = Object.keys(colors) as Array<keyof IStoreColors>;

  for (const key of keys) {
    const formatKey = key.replace(/([A-Z])/g, '-$1').toLowerCase();
    string += `--${formatKey}: ${colors[key]}; `;
  }

  return string;
}

const formattedColors = formatColors(COLORS);

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -ms-overflow-style: none;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  html, body, #root {
    height: 100%;
    font-family: 'Open Sans', sans-serif;
    overflow: hidden;

    &::-webkit-scrollbar {
      width: 0;
      background: transparent; 
    }
    
    &::-webkit-scrollbar-thumb {
      background: #FF0000;
    }
  }

  *, button, input {
    border: 0;
    outline: 0;

    font-family: 'Open Sans', sans-serif;
  }

  :root {
    ${formattedColors}
  }
`;