import provider from './config';

export const postRegister = async (payload: any) => {
  try {
    const response = await provider.post('/entidades/cadastro', payload)

    return response;
  } catch (err: any) {
    throw Error ('Erro ao enviar os dados.')
  }
}