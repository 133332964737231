/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import format from 'date-fns/format';
interface ICellProps {
  type?: string;
  options?: Array<string>;
  editavel: boolean;
  mascara?: string;
  value: string | boolean;
  precisao?: number;
  moeda?: string;
  onChange?: (value: string) => void;
}

const CellComponent: React.FC<ICellProps> = ({
  type = "texto",
  value,
  options,
  editavel,
  mascara,
  precisao = 2,
  moeda = 'BRL',
  onChange = () => { }
}) => {
  const [cellValue, setCellValue] = useState<any>(value);
  const [focus, setFocus] = useState(false);

  useEffect(() => {
    if (typeof cellValue === 'object') {
      onChange(cellValue);
    } else {
      onChange(cellValue as string);
    }
  }, [cellValue]);

  useEffect(() => {
    setCellValue(value);
  }, [value]);

  const renderCell = () => {
    switch (type) {
      case "texto":
        return (focus && editavel) ? <input
          type="text"
          value={cellValue as string}
          onChange={(e) => setCellValue(e.target.value)}
          onBlur={(e) => setFocus(false)}
          readOnly={!editavel}
        /> : <p onClick={(e) => setFocus(true)} className={!isNaN(cellValue) ? 'number' : ''}>{
          cellValue 
          ? mascara 
            ? mascara.startsWith('data')
              ? format(new Date(cellValue as string), 'dd/MM/yyyy HH:mm') 
              : mascara === 'dinheiro' ? new Intl.NumberFormat([], {
                style: 'currency',
                currency: moeda,
                minimumFractionDigits: precisao
              }).format(cellValue) : cellValue : cellValue : "---"}</p>;
      case "checkbox":
        return (focus && editavel) ?
          <input
            type="checkbox"
            checked={cellValue ? true : false}
            onChange={() => { cellValue ? setCellValue('') : setCellValue('1') }}
            onBlur={(e) => setFocus(false)}
            readOnly={!editavel}
          /> : <p onClick={(e) => setFocus(true)}>{(cellValue ? "Sim" : "Não")}</p>;
      case "lookup":
        return <p>{typeof cellValue === 'object' ? cellValue ? cellValue.nome ? cellValue.nome : cellValue.codigo ? cellValue.codigo : cellValue.id : '---' : cellValue}</p>
      case "select":
        return (focus && editavel && options) ? (
          <select
            name="select"
            defaultValue={cellValue as string}
            onBlur={(e) => setFocus(false)}
            disabled={!editavel}
          >
            {options.map(option => {
              return (<option value={option}>{option}</option>);
            })}
          </select>
        ) : <p onClick={(e) => setFocus(true)}>{cellValue ? cellValue : "---"}</p>;
      case "upload":
        return (focus && editavel) ? <input
          type="file"
          value=""
          onChange={() => { }}
          onBlur={(e) => setFocus(false)}
          readOnly={!editavel}
        /> : cellValue ? <img src={cellValue} width="120" alt={cellValue} onClick={(e) => setFocus(true)} />:  <p onClick={(e) => setFocus(true)}>---</p>;
      default:
        return (focus && editavel) ? <input
          type="text"
          value={cellValue as string}
          onChange={(e) => setCellValue(e.target.value)}
          onBlur={(e) => setFocus(false)}
          readOnly={!editavel}
        /> : <p onClick={(e) => setFocus(true)}>{
          cellValue 
          ? mascara 
            ? mascara.startsWith('data')
              ? format(new Date(cellValue as string), 'dd/MM/yyyy HH:mm') 
              : mascara === 'dinheiro' ? new Intl.NumberFormat([], {
                style: 'currency',
                currency: 'USD'
              }).format(cellValue) : cellValue : cellValue : "---"}</p>;
    }
  }

  return <div>{renderCell()}</div>;
}

export default CellComponent;