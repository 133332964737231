import Provider from './config'

export const getDashboard = async (id: any) => {
  try {
    const result = await Provider.get('exchanges/account/dashboard', {
      params: {idUser: id}
    })

    return result.data;
  } catch (err: any) {
    throw Error ('Erro ao buscar valores da dashboard')
  }
}