import styled from 'styled-components';

interface ISpace {
  wide?: string;
  padd?: string;
  jContent?: string;
  aItems?: string;
}

export const Container = styled.div`
  padding: 16px 0;
  height: 100%;
  width: 100%;
`;

export const Content = styled.div`
  background: var(--black);
  border: none;
  border-radius: 10px;
  height: 100vh;
  width: 100%;
`;

export const Row = styled.div<ISpace>`
  display: flex;
  justify-content: ${props => props.jContent ? props.jContent : 'space-between'};
  align-items: ${props => props.aItems ? props.aItems : 'flex-start'};

  & > span > svg{
    animation: spin 1s linear infinite;
    color: var(--primary);
    font-size: 22px;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(359deg); }  
  }
`;

export const Column = styled.div<ISpace>`
  color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: ${props => props.jContent ? props.jContent : 'flex-start'};
  padding: ${props => props.padd ? props.padd : '20px'};
  width: ${props => props.wide ? props.wide : '100%'};

  h2{
    font-size: 2.3rem;
    margin-bottom: 10px;
  }

  .subtitle{
    color: var(--primary-text-color);
  }

  label{
    display: flex;
  }

  input{
    background: transparent;
    border: 1px solid var(--muted);
    border-radius: 6px;
    color: var(--white);
    padding: 4px;
  }

  textarea{
    background: transparent;
    border: 2px solid var(--muted);
    border-radius: 10px;
    color: var(--white);
    margin-top: 10px;
    max-height: 250px;
    max-width: 100%;
    min-height: 200px;
    min-width: 80%;
    padding: 10px;
    width: 100%;
  }

  span{
    color: var(--warning);
    font-size: 1.2rem;
    margin-left: 4px;
  }
`;

export const Card = styled.div`
  background: var(--form-background);
  border: 2px solid var(--form-selected);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-left: 20px;

  iframe{
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    height: 300px;
    width: 70%;
  }
`;

export const TitleCard = styled.label`
  color: var(--white);
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 4px;
`;

export const Description = styled.p`
  color: var(--muted);
  font-size: 0.9rem;
  text-align: justify;
  width: 90%;
`;

export const IndexCard = styled.div`
  background: var(--form-selected);
  border: none;
  border-radius: 10px;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  height: 50px;
  width: 50px;
  
  p{
    font-size: 2rem;
  }
`;

export const Form = styled.form``;