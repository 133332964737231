import styled from 'styled-components'
import ButtonLayout from '../../components/Button'

interface IButton {
  background?: string;
  wide?: string;
}

export const Container = styled.div`
  padding: 16px 0;
  width: 100%;
`;

export const Content = styled.div`
  background-color: var(--form-background);
  border-radius: 2px;
  border-left: 1px solid var(--opacity-background);
  border-right: 1px solid var(--opacity-background);
  border-top: 1px solid var(--opacity-background);
  color: var(--black);
  margin-top: 20px;
  overflow: auto;

  position: relative;

  h1 {
    width: 100%;
    font-size: 20px;
    background-color: var(--primary);
    color: var(--black);
    font-weight: 200;
    margin-bottom: 10px;
    padding-left: 5px;
    border-radius: 5px;
  }
  h2 {
    width: 100%;
    font-size: 18px;
    background-color: var(--secondary);
    color: var(--black);
    font-weight: 200;
    margin-bottom: 10px;
    padding-left: 5px;
    border-radius: 5px;
  }
`;

export const Title = styled.h2`
  color: var(--black);
  font-size: 24px;
  margin-bottom: 16px;
`;

export const DivCard = styled.div`
  background-color: var(--form-background);
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  min-height: 60px;
  width: 100%;

  & > div:nth-child(2){
    justify-content: flex-end;
  }

  @media(max-width: 1024px){
    .filters {
      width: 100%;
    }  
  }

`;

export const Row = styled.div<IButton>`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  width: ${props => props.wide ? props.wide : '100%'};

  div{
    margin-right: 100px;
  }

  label{
    color: var(--black);
    font-size: 22px;
    margin-right: 60px;
  }
  
  .buttonOrder{
    background: transparent;
    padding: 0;
  }
  
  p{
    color: var(--black);
    cursor: pointer;
    font-size: 1rem;
  }
  
  p:first-child{
    margin-right: 20px;
  }
  
  .primary{
    color: var(--primary-lighter);
  }
  
  & > span > svg{
    color: var(--black);
    font-size: 26px;
    animation: spin 1s linear infinite;
  }
  
  select{
    border: 1px solid var(--muted);
    border-radius: 6px;
    background: transparent;
    color: var(--black);
    margin-right: 10px;
    padding: 4px 8px;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(359deg); }  
  }
  
  `;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  
  .flex-end{
    border-top: 1px solid var(--black);
    justify-content: flex-end;
    margin-top: 14px;
    padding: 10px 16px;

    & :nth-child(2){
      margin-right: 0;
    }
  }

  .input-group {
    display: flex;
    align-content: stretch;
    height: 30px;
  }

  .input-group > input {
    flex: 1 0 auto;
    width: 122px;
  }

  .input-group-addon {
    background: var(--primary);
    border: 1px solid var(--primary);
    border-radius: 10px;
    padding: 5px 10px;
  }

  select, input{
    background: var(--form-background);
    border: 1px solid var(--muted);
    border-radius: 6px;
    color: var(--white);
    padding: 4px;
  }

  select{
    width: 156px;
  }

  select:focus, input:focus{
    box-sizing: border-box;
    outline: 2px solid var(--primary-lighter);
    outline-offset: -2px;
  }
`;

export const RowModal = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding: 0 16px;
  width: 100%;
`;

export const Button = styled(ButtonLayout)<IButton>`
  background: ${props => props.background};
  align-self: flex-end;
  margin-right: 16px;
  width: ${props => props.wide ? props.wide : "130px"};
  white-space: nowrap;

  & > span > svg{
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(359deg); }  
  }
`;

export const Form = styled.form``;

export const Footer = styled.div`
  background-color: var(--form-background);
  border: 1px solid var(--opacity-background);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  
  width: 100%;
`;

export const SelectedContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 800;

  & > p {
    color: var(--primary-lighter);
    display: flex;
    align-items: center;
    font-size: 12px;
    text-transform: uppercase;

    & > b {
      /* font-weight: 500; */
      margin-left: 4px;
    }
  }
`;

export const PaginateContainer = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  margin-left: auto;


  & .active-page-item {
    background-color: var(--primary);
    color: var(--white);
  }

  ul {
    display: flex;
    list-style: none;
    justify-content: space-between;

    li {
      padding: 4px 8px;
      margin: 0 2px;
      cursor: pointer;
      color: var(--primary-text-color);
      background-color: var(--white);
      transition: .2s;
      border-radius: 2px;
      border: 1px solid var(--input-border-blurred);

      &:hover {
        background-color: var(--primary);
        color: var(--white);
      }

      &:active {
        background-color: var(--primaryDarker);
      } 
    }
  }
`;
